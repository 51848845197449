import Vue from 'vue';
const eventHub = new Vue();
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {

	watch: {},

	created: function() {
		let cookieData = VueCookies.get(this.cookieKey);
		let searchData = VueCookies.get(this.searchKey);
		this.token = (cookieData) ? cookieData.token : '';
		this.searchKeyword = (searchData) ? searchData.search : '';

		if (cookieData) {
			this.usersFullname = this.toTitleCase(cookieData.user.firstname + ' ' + cookieData.user.lastname);
			this.userData = cookieData.user;
			this.usersModules = cookieData.user.modules
		}
	},

	data: function() {
		return {
			searchKey: 'supersecret',
			cookieKey: 'sgcchris',
      apiUrl: 'https://hris.sgccserver02.com/api',
			eventHub: eventHub,
			//apiUrl: 'http://localhost/sterling-hris/api',
			token: '',
			formRules: {
				numberRequired: value => !(value <= 0) || 'Should be more than zero',
				required: value => !!value || 'Required',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
				phNumber: (inputNumber) => {
					let reg = new RegExp(/^(\+639)\d{9}$/);
					return (reg.test(inputNumber)) ? true : 'Must be valid PH Mobile Number e.g(+639XXXXXXXXX)';
				}
			},
			userData: {},
			drawer: true,
			usersFullname: '',
			usersPosition: '',
			usersModules: [],
			modules: [
			{
				name: 'Employee',
				iconClass: 'fa-users',
				color: 'blue-7',
				bgClass: 'bg-blue-7',
				textClassColor: 'text-blue-7',
				code: 'employee-management',
				allowedmenu: 'employees',
				path: 'employee-management/dashboard'
			},
			{
				name: 'Online Forms',
				iconClass: 'fa-file-alt',
				color: 'red-7',
				bgClass: 'bg-red-7',
				textClassColor: 'text-red-7',
				code: 'online-forms',
				allowedmenu: 'forms',
				path: 'online-forms/my-form-applications',
			},
			{
				name: 'Leaves',
				iconClass: 'fa-walking',
				color: 'amber-7',
				bgClass: 'bg-amber-7',
				textClassColor: 'text-amber-7',
				code: 'leaves',
				allowedmenu: 'leaves',
				path: 'leaves/my-leave-applications'
			},
			{
				name: 'Recruitment',
				iconClass: 'fa-address-book',
				color: 'indigo-6',
				bgClass: 'bg-indigo-6',
				textClassColor: 'text-indigo-6',
				code: 'recruitment',
				allowedmenu: 'recruitment',
				path: 'recruitment/appointment-list'
			},
			{
				name: 'Training',
				iconClass: 'fa-chalkboard-teacher',
				color: 'brown',
				bgClass: 'bg-brown',
				textClassColor: 'text-brown',
				code: 'training',
				allowedmenu: 'training',
				path: 'training'
			},
			{
				name: 'TimeKeeping',
				iconClass: 'fa-user-clock',
				color: 'grey-7',
				bgClass: 'bg-blue-grey-7',
				textClassColor: 'text-blue-grey-7',
				code: 'timekeeping',
				allowedmenu: 'timekeeping',
				path: 'timekeeping/home/dashboard'
			},
			{
				name: 'Payroll',
				iconClass: 'fa-file-invoice-dollar',
				color: 'green-9',
				bgClass: 'bg-green-9',
				textClassColor: 'text-green-9',
				code: 'payroll',
				allowedmenu: 'payroll',
				path: 'employee-management/dashboard'
			},
        // {
        //   name: 'Clearance',
        //   iconClass: 'fa-user-check',
        //   color: 'purple-9',
        //   bgClass: 'bg-purple-9',
        //   textClassColor: 'text-purple-9',
        //   code: 'clearance',
        //   allowedmenu: 'clearance',
        //   path: 'clearance'
        // },
			{
				name: 'Payslip',
				iconClass: 'fa-file-invoice',
				color: 'teal-9',
				bgClass: 'bg-teal-9',
				textClassColor: 'text-teal-9',
				code: 'payslip',
				allowedmenu: 'payslip',
				path: 'payslip/payslip-dashboard'
			},
			{
				name: 'Backend',
				iconClass: 'fas fa-cogs',
				color: 'lime-10',
				bgClass: 'bg-lime-10',
				textClassColor: 'text-lime-10',
				code: 'backend',
				allowedmenu: 'backend',
				path: 'backend/backend-list'
			},
        // {
        //   name: 'Announcement',
        //   iconClass: 'fas fa-bullhorn',
        //   color: 'orange-10',
        //   bgClass: 'bg-orange-10',
        //   textClassColor: 'text-orange-10',
        //   code: 'announcement',
        //   allowedmenu: 'announcement',
        //   path: 'announcement/announcement-list'
        // },
			{
				name: 'Knowledge Database',
				iconClass: 'fas fa-database',
				color: 'blue-10',
				bgClass: 'bg-blue-10',
				textClassColor: 'text-blue-10',
				code: 'database',
				allowedmenu: 'database',
				path: 'database/database-dashboard'
			},
			{
				name: 'Admin Settings',
				iconClass: 'fas fa-users-cog',
				color: 'red-14',
				bgClass: 'bg-red-14',
				textClassColor: 'text-red-14',
				code: 'settings',
				allowedmenu: 'settings',
				path: 'settings'
			},
        //ruben::08/08/2023
        // pantry
			{
				name: 'Pantry',
				iconClass: 'fas fa-utensils',
				color: 'pink-14',
				bgClass: 'bg-pink-14',
				textClassColor: 'text-grey-10',
				code: 'pantry',
				allowedmenu: 'pantry',
				path: 'pantry/sync-employees'
			},
			//mark::11/25/2024  <i class="fa-solid fa-money-bill-trend-up"></i>
			{
			  name: 'Finance',
			  iconClass: 'fas fa-coins',
			  color: 'orange-10',
			  bgClass: 'bg-orange-10',
			  textClassColor: 'text-orange-10',
			  code: 'finance',
			  allowedmenu: 'finance',
			  path: 'finance/account-receivable'
			},
			]
		}
	},

	methods: {
		toTitleCase: function(str) {
      return str.replace(/\w\S*/g, function(txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
  },
  humanize: function(str) {
  	return str.split('-').map(function capitalize(part) {
  		return part.charAt(0).toUpperCase() + part.slice(1);
  	}).join(' ');
  },
  toLowerCaseAndDashSpace: function(str) {
  	return str.replace(/\s+/g, '-').toLowerCase();
  },
  replaceSpecialCharsForUrlWp: function(str) {
  	return str.replace(/\"|\(|\)|\.|\,/g, ' ');
  },
  numberWithCommas: function(x) {
  	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  truncateText: function(text, length, clamp) {
  	clamp = clamp || '...';
  	var node = document.createElement('div');
  	node.innerHTML = text;
  	var content = node.textContent;
  	return content.length > length ? content.slice(0, length) + clamp : content;
  },
  splitFirstnameLastname: function(fullname) {
  	let tempString = fullname.split(' '),
  	firstName = tempString[0],
  	lastName = tempString[tempString.length - 1];

  	return {
  		firstName: firstName,
  		lastName: lastName
  	}
  },
  removeFullnameSpace: function(fullname) {
  	return fullname.replace(/\s+/g, '');
  }
}
};
