<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="col-12 q-mb-md right">
        <h4 class="q-ma-none">Masterlist of Internal Records </h4><br />
      </div>
      <div class="col-12 q-mb-md">
        <div class="col-12 q-mb-md">
            <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs"
            :data="masterlist"
            :columns="columns"
            row-key="id" separator="cell"
            no-data-label="I didn't find anything for you!"
            :pagination.sync="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort>
            <template v-slot:body="props">
              <q-tr slot="body" :props="props">
               <q-td key="department" :props="props">{{ props.row.department }}</q-td>
               <q-td key="dc_no" :props="props">{{ props.row.dc_no }}</q-td>
               <q-td key="title" :props="props"><a href="#" @click="viewFileData(props.row.path)">{{ props.row.title }}</a></q-td>
                <q-td key="dc_control" :props="props">{{ props.row.dc_control }}</q-td>
                <q-td key="rev_no" :props="props">{{ props.row.rev_no }}</q-td>
                <q-td key="rev_date" :props="props">{{ props.row.rev_date }}</q-td>
                <q-td key="reason" :props="props">{{ props.row.reason }}</q-td>
                <q-td key="originator" :props="props">{{ props.row.originator }}</q-td>
                <q-td key="effectivity_date" :props="props">{{ props.row.effectivity_date }}</q-td>
                <q-td key="status" :props="props">{{ props.row.status }}</q-td>
              </q-tr>
            </template>
            <template v-slot:no-data="">
              <div class="full-width row flex-center text-red q-gutter-sm">
                <i class="fas fa-exclamation-triangle"></i>
                <span>The filter didn't uncover any results!</span>
              </div>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing>
                    <q-spinner-hourglass  size="50px" color="cyan" />
                </q-inner-loading>
            </template>
            <template v-slot:top-left="props">
              <q-input dense v-model="filter" placeholder="Search">
                <i class="fas fa-search" slot="append"></i>
              </q-input>
            </template>
          </q-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';
export default {
  name: 'Masterlist',
  data() {
    return {
        filter: '',
        loading: false,
        pagination: {
            page: 1,
            rowsPerPage: 15,
            rowsNumber: 10
        },

      columns: [
      { name: 'department', align: 'center', label: 'Department', field: 'department', sortable: true },
      { name: 'dc_no', align: 'center', label: 'No.', field: 'dc_no', sortable: true },
      { name: 'title', align: 'center', label: 'Document Title', field: 'title', sortable: true },
      { name: 'dc_control', align: 'center', label: 'DC Control', field: 'dc_control', sortable: true },
      { name: 'rev_no', align: 'center', label: 'Revision Number', field: 'rev_no', sortable: true },
      { name: 'rev_date', align: 'center', label: 'Revision Date', field: 'rev_date', sortable: true },
      { name: 'reason', align: 'center', label: 'Reason for Revision', field: 'reason', sortable: true },
      { name: 'originator', align: 'center', label: 'Originator', field: 'originator', sortable: true },
      { name: 'effectivity_date', align: 'center', label: 'Effectivity Date', field: 'effectivity_date', sortable: true },
      { name: 'status', align: 'center', label: 'Status', field: 'status', sortable: true },
      ],
      masterlist: [],
    }

  },

  created() {
    this.getMasterlist()
  },


  methods: {
      //start if server side
      onRequest (props) {
        const { page, rowsPerPage } = props.pagination
        const filter = props.filter
        this.loading = true
        setTimeout(() => {
          this.getRowsNumberCount(filter).then(total => {
              this.pagination.rowsNumber = total
          })
          const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
          const startRow = (page - 1) * rowsPerPage
          this.fetchFromServer(startRow, fetchCount, filter)
          this.pagination.page = page
          this.pagination.rowsPerPage = rowsPerPage
        }, 1500)
      },

      fetchFromServer (startRow, count, filter) {
          axios.get(this.apiUrl + '/knowledge/masterlist', {
              headers: { 'Authorization': `Bearer ${this.token}` },
              params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
              }
          }).then((response) => {
              this.masterlist = response.data.data
              this.loading = false
          }).catch((error) => {
              console.log(error);
          })
     },

      async getRowsNumberCount (filter,from_Date,to_Date) {
         let response = await axios.get(this.apiUrl + '/knowledge/masterlist/count', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
                filter: filter,
                fromDate: from_Date,
                toDate: to_Date
             }
         })
         return response.data
      },

      ///end of server side
      getMasterlist: function() {
         this.onRequest({pagination:this.pagination,filter:''})
      },

      viewFileData(fileName){
          window.open(fileName, '_blank');
      },
   },


}
</script>
<style lang="css" scoped>
</style>
