<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row ">
      <div class="col-12 q-mb-md">
        <h4 class="q-ma-none">COE Request List</h4>
      </div>
      <div class="col-12 q-mb-md">
        <q-table  :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="Employees"
        :data="coeResultData"
        :columns="coeResultColumns"
        row-key="id" separator="cell"
        no-data-label="I didn't find anything for you!"
        :pagination.sync="pagination"
        :loading="loading"
        :filter="filter"
        @request="onRequest"
        binary-state-sort>
          <q-tr slot="body" slot-scope="props" :props="props">
            <q-td key="employee_id" :props="props">{{ props.row.employee_id }}</q-td>
            <q-td key="name" :props="props">{{ props.row.name }}</q-td>
            <q-td key="position" :props="props">{{ props.row.position }}</q-td>
            <q-td key="request_date" :props="props">{{ props.row.request_date }}</q-td>
            <q-td key="reason" :props="props" @click="viewCOE(props.row)">
               <span class="text-blue">{{ props.row.reason }}
                  <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]">
                    <strong>Click to View Details</strong>
                  </q-tooltip>
               </span>
            </q-td>
            <q-td key="status" :props="props">
               <q-chip outline :color="props.row.status == 'PENDING' ? 'orange' : props.row.status == 'PROCESSING' ?  'teal': props.row.status == 'RELEASED' ?  'green': '' "
               text-color="white" :icon="props.row.status == 'PENDING' ? 'fa fa-clock' : props.row.status == 'PROCESSING' ?  'fa fa-thumbs-up': props.row.status == 'RELEASED' ?  'fa fa-check-circle': '' ">
                {{ props.row.status }}
             </q-chip>
            </q-td>
            <q-td key="action" :props="props">
               <div class="q-gutter-sm">
                <q-btn rounded outline size="sm" color="orange" v-show="props.row.status == 'PENDING'" @click="acceptRequest(props.row)">
                  ACCEPT
                  <q-tooltip anchor="top middle" content-class="bg-orange" self="bottom middle" :offset="[10, 10]" >
                    <strong>Accept Request</strong>
                  </q-tooltip>
                </q-btn>
                <q-btn rounded outline size="sm" color="green" v-show="props.row.status == 'PROCESSING'" @click="releaseCOE(props.row)">
                  RELEASE
                  <q-tooltip anchor="top middle" content-class="bg-green" self="bottom middle" :offset="[10, 10]">
                    <strong>Release COE</strong>
                  </q-tooltip>
                </q-btn>
                <q-btn rounded outline size="sm" color="red" v-show="props.row.status == 'RELEASED'" @click="printCOE(props.row)" icon="fa fa-print" label="PRINT">
                  <q-tooltip anchor="top middle" content-class="bg-red" self="bottom middle" :offset="[10, 10]">
                    <strong>Print COE</strong>
                  </q-tooltip>
                </q-btn>
              </div>
            </q-td>
          </q-tr>
          <template v-slot:no-data="{ message }">
            <div class="full-width row flex-center text-amber-10 q-gutter-sm">
              <span>
                {{message}}
              </span>
              <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
            </div>
          </template>
        </q-table>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow loading-width">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-lg">
            <div class="text-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{ loadingMessage }}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <COEDetails></COEDetails>
  </div>
</template>
<script>
import axios from 'axios';
import COEDetails from './COERequestDetails';
export default {
  name: 'COERequestList',
  components: {
    "COEDetails": COEDetails,
  },
  data() {
    return {
      filter: '',
      pagination: {
          page: 1,
          rowsPerPage: 15,
          rowsNumber: 10
      },
      loadingModel: false,
      loading: false,
      coeResultData: [],
      loadingMessage:'',
      coeResultColumns: [
         { name: 'employee_id',field: 'employee_id', label: 'Employee ID', style: 'width: 160px', align: 'center',headerStyle: 'width: 160px'},
         { name: 'name', field: 'name', label: 'Name', style: 'width: 230px', align: 'center', headerStyle: 'width: 230px'},
         { name: 'position', field: 'position', label: 'Position', style: 'width: 250px', align: 'center', headerStyle: 'width: 250px'},
         { name: 'request_date', field: 'request_date', label: 'Date Request', style: 'width: 200px', align: 'center', headerStyle: 'width: 200px'},
         { name: 'reason', field: 'reason', label: 'Reason', align: 'center'},
         { name: 'status', field: 'status', label: 'Status', align: 'center'},
         { name: 'action', field: 'action', label: 'Action', style: 'width: 250px', align: 'center', headerStyle: 'width: 250px'},
      ],
    }
  },

  created() {
    this.getCOERequest()
  },

  methods: {
     getCOERequest: function() {
          this.onRequest({pagination:this.pagination,filter:''})
     },

     //start if server side
     onRequest (props) {

      const { page, rowsPerPage } = props.pagination

      const filter = props.filter
      this.loading = true
      setTimeout(() => {
         this.getRowsNumberCount(filter).then(total => {
             this.pagination.rowsNumber = total.data
         })
         const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
         const startRow = (page - 1) * rowsPerPage
         this.fetchFromServer(startRow, fetchCount, filter)
         this.pagination.page = page
         this.pagination.rowsPerPage = rowsPerPage
      }, 1500)
     },

     fetchFromServer (startRow, count, filter) {
         axios.get(this.apiUrl + '/online-forms/applications/coe/list', {
             headers: { 'Authorization': `Bearer ${this.token}` },
             params: {
               start: startRow,
               countPerPage: count,
               filter: filter,
             }
         }).then((response) => {
             this.coeResultData = response.data.data
             this.loading = false
         }).catch((error) => {
             console.log(error);
         })
    },

     async getRowsNumberCount (filter) {
        let response = await axios.get(this.apiUrl + '/online-forms/applications/coe/total', {
            headers: { 'Authorization': `Bearer ${this.token}` },
            params: {
               filter: filter,
            }
        })
        return response.data
     },

     ///end of server side

     acceptRequest: function(obj){
        this.loadingModel = true;
        this.loadingMessage = 'Accepting request...';
         let formData = new FormData();
         formData.append('form_data', JSON.stringify(obj));

         axios.create({
           baseURL: this.apiUrl,
           headers: {
             'Authorization': `Bearer ${this.token}`
           }
         }).post('/online-forms/applications/coe/accept', formData).then((res) => {
             if(res.data.status){
                 setTimeout(() => {
                   this.loadingModel = false;
                   this.$q.notify({
                     color: 'green-7',
                     textColor: 'white',
                     icon: 'fa fa-check',
                     message: res.data.message
                   });
                   this.getCOERequest();
                 }, 1000);
             }else{
                 setTimeout(() => {
                   this.loadingModel = false;
                   this.$q.notify({
                     color: 'red-7',
                     textColor: 'white',
                     icon: 'fa fa-times',
                     message: res.data.message
                   });
                   this.getCOERequest();
                 }, 1000);
             }

         });
     },

     releaseCOE: function(obj){
        this.loadingModel = true;
        this.loadingMessage = 'Releasing request...';
         let formData = new FormData();
         formData.append('form_data', JSON.stringify(obj));

         axios.create({
           baseURL: this.apiUrl,
           headers: {
             'Authorization': `Bearer ${this.token}`
           }
        }).post('/online-forms/applications/coe/release', formData).then((res) => {
           console.log(res.data)
             if(res.data.status){
                 setTimeout(() => {
                   this.loadingModel = false;
                   this.$q.notify({
                     color: 'green-7',
                     textColor: 'white',
                     icon: 'fa fa-check',
                     message: res.data.message
                   });
                   this.getCOERequest();
                 }, 1000);
             }else{
                 setTimeout(() => {
                   this.loadingModel = false;
                   this.$q.notify({
                     color: 'red-7',
                     textColor: 'white',
                     icon: 'fa fa-times',
                     message: res.data.message
                   });
                   this.getCOERequest();
                 }, 1000);
             }

         });
     },

     printCOE: function(obj){
        window.open(this.apiUrl+'/online-forms/applications/coe/generate?id='+obj.id, '_blank');
     },

     viewCOE: function(obj){
        this.eventHub.$emit('COEDetails', { stat: true, obj: obj });
     }
  }
}
</script>
<style lang="css" >

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 5px;
}

::-webkit-scrollbar {
    height: 12px;
    width: 14px;
    background: transparent;
    z-index: 12;
    overflow: visible;
}

::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #c1c1c1;
    border-radius: 10px;
    z-index: 12;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    transition: background-color .32s ease-in-out;
    margin: 4px;
    min-height: 32px;
    min-width: 32px;
}

::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
}

</style>
