<template>
  <q-layout view="lHh LpR fFf">
    <q-header elevated :class="selectedModule.bgClass" >
      <q-toolbar v-if="isEmployee">
        <q-btn flat class="q-mr-md" @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="fas fa-bars" text-color="white">
          <q-tooltip anchor="top middle" content-class="bg-black" self="bottom middle" :offset="[20, 20]">
            <span v-if="leftDrawerOpen"><strong>Close Drawer</strong></span>
            <span v-else><strong>Open Drawer</strong></span>
          </q-tooltip>
        </q-btn>
        <q-btn class="q-mr-sm" stretch flat @click="$router.replace('/home')" icon="fas fa-home" text-color="white" style="width: 70px;">
          <q-tooltip anchor="top middle" content-class="bg-black" self="bottom middle" :offset="[10, 10]">
            <strong>Home</strong>
          </q-tooltip>
        </q-btn>
        <q-btn class="q-mr-sm" v-for="(module, index) in uniqueMenu" v-bind:key="index" stretch :flat="module.code != currentModule" :color="module.code == currentModule ? 'white' : ''" :text-color="module.code == currentModule ? selectedModule.color : 'white'" @click="selectMenu(module.code)" style="width: 70px;" align="center">
          <i class="fa fa-2x" :class="module.iconClass"></i>
          <q-tooltip anchor="top middle" :content-class="module.bgClass" self="bottom middle" :offset="[10, 10]">
            <strong>{{ module.name }}</strong>
          </q-tooltip>
        </q-btn>
        <q-toolbar-title class="">
          <!-- {{selectedModule.name}} -->
        </q-toolbar-title>
        <!-- START:: Button for notifications -->
        <q-btn class="q-mr-sm" stretch flat icon="fas fa-bell" text-color="white" style="width: 70px;" @click="notificationBell">
          <q-badge class="absolute-bottom-right" color="orange" text-color="black" v-if="count != 0" v-html="count"></q-badge>
          <q-tooltip anchor="top middle" content-class="bg-black" self="bottom middle" :offset="[10, 10]">
            <strong>Notifications</strong>
          </q-tooltip>
        </q-btn>
        <!-- END:: Button for notifications -->
        <q-avatar size="52px">
          <img src="../assets/boy-avatar.png">
        </q-avatar>
        <div class="text-white">
          <q-btn-dropdown :label="fullname" flat>
            <q-list>
              <q-item clickable v-close-popup @click="myProfile">
                <q-item-section avatar class="text-green">
                  <i class="fa fa-user fa-2x"></i>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Profile</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="changePassword">
                <q-item-section avatar class="text-cyan">
                  <i class="fa fa-key fa-2x"></i>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Change Password</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="logOut">
                <q-item-section avatar class="text-red">
                  <i class="fa fa-power-off fa-2x"></i>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
      <q-toolbar v-else class="glossy">
        <q-toolbar-title class="q-ml-md text-weight-light">
          <strong>Welcome</strong> New Applicant!
        </q-toolbar-title>
        <div>
          <q-btn flat @click="logOut">
            <q-tooltip content-class="bg-accent">Sign Out</q-tooltip>
            <q-icon name="fa fa-sign-out-alt" />
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>
    <q-drawer side="left" elevated v-model="leftDrawerOpen" bordered content-class="bg-grey-2" class="left-panel" :width="300">
      <q-list bordered separator>
        <q-item-label header class="bg-white hitler">
          <h4 class="no-margin">
            <q-item clickable tag="a" href="/home" class="text-center">
              <div class="full-width">
                <span :class="[selectedModule.textClassColor, moduleHeader]">SGCC</span>&nbsp;<span class="menu-header">HRIS</span>
              </div>
            </q-item>
          </h4>
        </q-item-label>
        <q-separator />
        <div class="sidebg">
          <q-scroll-area style="height: calc(100% - 150px); border-right: 1px solid #ddd">
            <template v-for="(navigation, index) in navigations">
              <template v-if="navigation.sub === undefined">
                <q-item clickable v-bind:key="index" :to=" '/' + selectedModule.code + navigation.route" :class="navigation.textColor" :active-class="navigation.activeColor">
                  <q-item-section avatar>
                    <i class="fa fa-2x" :class="navigation.iconClass"></i>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label><strong>{{navigation.name}}</strong></q-item-label>
                  </q-item-section>
                </q-item>
              </template>
              <template v-if="navigation.sub">
                <q-expansion-item v-bind:key="index" :label="navigation.name" default-opened dense-toggle expand-separator :content-inset-level="0.5" :class="[qExpandItem, navigation.textColor]" header-style="font-weight: bold">
                  <q-item clickable v-for="(sub, x) in navigation.sub" v-bind:key="x" :to=" '/' + selectedModule.code + sub.route" :class="sub.textColor" :active-class="sub.activeColor">
                    <q-item-section avatar>
                      <i class="fa fa-2x" :class="sub.iconClass"></i>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label><strong>{{sub.name}}</strong></q-item-label>
                    </q-item-section>
                  </q-item>
                </q-expansion-item>
              </template>
            </template>
          </q-scroll-area>
        </div>
      </q-list>
    </q-drawer>
    <q-page-container>
      <vue-page-transition name="fade-in-left">
        <router-view />
      </vue-page-transition>
      <ChangePassword></ChangePassword>
      <Profile></Profile>
      <LogoutDialog></LogoutDialog>

      <!-- conversation start here -->
      <!--<ChatModal></ChatModal>-->
      <!-- conversation end here -->
    </q-page-container>
  </q-layout>
</template>
<script>
import axios from 'axios';
import ChangePassword from '../components/ChangePassword.vue';
import Profile from '../components/Profile.vue';
import LogoutDialog from '../components/LogoutDialog.vue';
//import ChatModal from '../components/announcement/AdminChatModal';

export default {
  name: 'Dashboard',
  components: {
    "ChangePassword": ChangePassword,
    "Profile": Profile,
    "LogoutDialog": LogoutDialog,
    //"ChatModal": ChatModal
  },

  data: () => ({
    isActive: false,
    selectedModule: {},
    isEmployee: true,
    leftDrawerOpen: true,
    navigations: [],
    subNavigations: [],
    qExpandItem: 'qExpandItem',
    showApprovals: false,
    fullname: null,
    allowedMenu: [],
    uniqueMenu: [],
    currentModule: '',
    moduleHeader: 'module-header',
    allUnread: 0,
    hrUnread: 0,
    notif_list: [],
    count: '',
    chat: [],
    chatNotif: 0,
    drawerRight: false,
    mychatmodal: true,
    chatEmployeeList: [],
    chatEmployeeTemp: [],
    sendToALl: { id: 0, empID: 'none', fullname: 'ANNOUNCEMENT', title: 'All Employees', type: 'all' },
    sendToHR: { id: 0, empID: 'none', fullname: 'HUMAN RESOURCES', title: 'HR Department', type: 'hr' },
    searchKey: '',
  }),

  sockets: {
    totalNotif: function(data) {
      this.getChatNotification();
      this.getChatEmpList();
    },

    read_badge: function(data) {
      if (data.receiver != 0) {
        this.getChatEmpList();
      } else {
        this.getChatEmpList();
        //this.getChatSendAll();
      }
    },

    read_announcement: function(data) {
      //console.log("read_announcement: ", data);
      //this.getChatEmpList();
      this.count = this.count + 1;
    },
  },

  mounted: function() {
     if(this.$route.path == '/payslip/payslip-dashboard' || this.$route.path == '/payslip'){
        this.leftDrawerOpen = false;
     }
    if (this.userData.username == 'NewApplicant') {
      this.isEmployee = false;
      this.leftDrawerOpen = false;
      this.selectedModule.bgClass = 'bg-indigo-6';
      this.$router.push('/recruitment/employment-application').catch(() => {});
    } else {
      this.fullname = this.userData.firstname + ' ' + this.userData.lastname
      for (let i in this.modules) {
        if (this.modules[i]['code'] == this.$route.params.module) {
          this.selectedModule = this.modules[i];
        }
      }
      this.setNav();
      this.setMenu(); //for navi menu
      this.currentModule = this.$route.params.module;
    }
  },

  created() {
    this.eventHub.$on('showChatModal', this.getChatEmpList);
    this.displayNotification();
    //this.getChatEmpList();
    //this.getChatNotification();

    this.isChatViewer = (this.userData.department == 1 || this.userData.id == 1) ? true : false; //HR and ADMIN
    // console.log(this.isChatViewer)
  },

  methods: {
    //START:: CHAT
    showChatModal: function(obj) {
      //console.log(obj.id)
      this.eventHub.$emit('openChat', { stat: true, obj: obj });
    },

    filterEmployee: function() {
      if (this.searchKey !== null) {
        let formData = new FormData();
        formData.append("keyword", this.searchKey);
        axios.create({
            baseURL: this.apiUrl,
            headers: { 'Authorization': `Bearer ${this.token}` }
          }).post('/search/receiver', formData)
          .then((res) => {
            this.chatEmployeeList = res.data.data
            //console.log(res.data.data)
          }).catch((error) => {
            console.log(error.response.status)
          });
      }
    },

    getChatEmpList: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/chat/employee/lists`)
        .then((res) => {
          this.chatEmployeeList = res.data.data;
          this.chatEmployeeTemp = res.data.data;
          // console.log(this.chatEmployeeList)
        })
    },

    readAnnouncement: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/readAnnouncement`)
        .then((res) => {
          // console.log("res: ", res.data.data);
          this.count = res.data.data;
        });
    },

    notificationBell: function() {
      //$router.replace('/announcement/announcement-list')
      const path = `/announcement/announcement-list`;
      if (this.$route.path !== path) {
        this.$router.push(path)
        location.reload();
      }
    },

    displayNotification: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/notification`)
        .then((res) => {
          this.notif_list = res.data.data;
          //this.count = res.data.count;
        });
    },

    getChatNotification: function() {
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get(`/employee/chat/notif`)
        .then((res) => {
          this.allUnread = 0 //(res.data.allUnread.totalAllUnread == '') ? 0 : res.data.allUnread.totalAllUnread
          this.hrUnread = res.data.hrUnread.totalHRUnread
          //console.log(res.data.allUnread)
          //console.log(res.data.hrUnread)
          this.chatNotif = res.data.data.totalUnread
          //console.log(res.data.data.totalUnread)
        });
    },

    selectMenu: function(module) {
      this.$router.replace('/' + module)
      location.reload();
    },

    setMenu: function() {
      const menuList = this.modules //get menu list
      const allowedMenu = this.userData //get allowed menu in token
      let isAdmin = (this.usersModules[1] !== undefined) ? true : false;
      if (isAdmin) {
        this.allowedMenu = menuList
      } else {
        for (let i in menuList) {
          if (menuList[i]['allowedmenu'] == 'forms' || menuList[i]['allowedmenu'] == 'leaves' || menuList[i]['allowedmenu'] == 'announcement' || menuList[i]['allowedmenu'] == 'payslip') { //make form and leaves default
            this.allowedMenu.push(menuList[i]);
          }
          for (let key in allowedMenu.modules) {
            if (menuList[i]['allowedmenu'] === allowedMenu.modules[key]['name']) {
              this.allowedMenu.push(menuList[i]);
            }
          }
        }
      }

      var unique = this.allowedMenu.filter(function(elem, index, self) { //remove duplicate menu
        return index === self.indexOf(elem);
      })

      this.uniqueMenu = unique;
    },

    logOut: function() {
      this.eventHub.$emit('logOut', { status: true });
    },

    changePassword: function() {
      this.eventHub.$emit('changePassword', { status: true });
    },

    myProfile: function() {
      this.eventHub.$emit('myProfile', { status: true });
    },

    setNav: function() {
      let isAdmin = (this.usersModules[1] !== undefined) ? true : false;

      switch (this.selectedModule.code) {
        case "employee-management":
          var employeesModules = (this.usersModules[2] !== undefined) ? this.usersModules[2] : false;
          var navsAvail = {
            "dashboard-employee": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-th',
              name: "Dashboard",
              route: "/dashboard"
            },
            "create-employee": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-user-plus',
              name: "Create Employee",
              route: "/create"
            },
            "list": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-users',
              name: "List of Employees",
              route: "/list"
            },
            "list-hold": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-user-tag',
              name: "Hold Employees",
              route: "/hold-employees"
            },
            "list-inactive": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-user-times',
              name: "Inactive Employees",
              route: "/inactive-employees"
            },
            "module-permissions": {
              textColor: 'colorEmployee',
              activeColor: 'activeColorEmployee',
              iconClass: 'fa-user-lock',
              name: "Module Permissions",
              route: "/module-permissions"
            },
            "employee-logs": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-user-edit",
              name: "Employee Logs",
              route: "/employee-logs",
            },
            // 06072022
             "for-review": {
               textColor: "colorEmployee",
               activeColor: "activeColorEmployee",
               iconClass: "fa-user-edit",
               name: "For Review",
               route: "/for-review",
             },
             // 01092023
            "new-employee": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-user-lock",
              name: "New Employees",
              route: "/new-employee",
            },
            "endorse-employee": {
              textColor: "colorEmployee",
              activeColor: "activeColorEmployee",
              iconClass: "fa-user-lock",
              name: "Endorse Employees",
              route: "/endorse-employee",
            }, // 01092023
            "endorse-reports": {
             textColor: "colorEmployee",
             activeColor: "activeColorEmployee",
             iconClass: "fa-user-lock",
             name: "Endorse Reports",
             route: "/endorse-reports",
           },
           //ruben::rehire 15-06-2023
           "rehire-employee": {
             textColor: "colorEmployee",
             activeColor: "activeColorEmployee",
             iconClass: "fa-user-secret",
             name: "Re-Hire Employee",
             route: "/rehire-employee",
           },
           "exit-interview": {
             textColor: "colorEmployee",
             activeColor: "activeColorEmployee",
             iconClass: "fa-podcast",
             name: "Exit Interview",
             route: "/sms-blast",
             sub: [{
                textColor: 'colorEmployee',
                activeColor: 'activeColorEmployee',
                iconClass: 'fa-podcast',
                name: "Form",
                route: "/exit-interview"
              },
              {
                textColor: 'colorEmployee',
                activeColor: 'activeColorEmployee',
                iconClass: 'fa-file-excel',
                name: "Report",
                route: "/exit-interview-report"
              }],
            },
          };

          for (let i in navsAvail) {
            if (isAdmin) {
              this.navigations.push(navsAvail[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/employee-management/dashboard');
              }
            } else {
              if (employeesModules) {
                for (let n in employeesModules.sub_modules) {
                  if (i == employeesModules.sub_modules[n]) {
                    this.navigations.push(navsAvail[i]);
                  }
                }
              }

              if (this.$route.name == "Dashboard") {
                this.$router.push('/employee-management/list');
              }
            }
          }

          break;

        case "payroll":
          var payroll = (this.usersModules[4] !== undefined) ? this.usersModules[4] : false;

          var payrollNav = {
            // "dashboard-employee": {
            //   textColor: 'colorPayroll',
            //   activeColor: 'activeColorPayroll',
            //   iconClass: 'fa-th',
            //   name: "Dashboard",
            //   route: "/dashboard"
            // },
            // "view-employee": {
            //   textColor: 'colorPayroll',
            //   activeColor: 'activeColorPayroll',
            //   iconClass: 'fa-users',
            //   name: "Employees",
            //   route: "/employee/view"
            // },
            // "detailed-dtr": {
            //   textColor: 'colorPayroll',
            //   activeColor: 'activeColorPayroll',
            //   iconClass: 'fa-stopwatch',
            //   name: "Detailed TK",
            //   route: "/detailed/tk"
            // },
            // "tk-summary": {
            //   textColor: 'colorPayroll',
            //   activeColor: 'activeColorPayroll',
            //   iconClass: 'fa-calendar-alt',
            //   name: "TK Summary",
            //   route: "/tk/summary"
            // },
            "process-payroll": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-file-invoice-dollar',
              name: "Process Payroll",
              route: "/process"
            },
            "payroll-history": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-file-invoice-dollar',
              name: "Payroll",
              route: "/payroll-history"
            },
            "adjusments": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-calendar-plus',
              name: "Adjustment/Deduction",
              route: "/adjusments"
            },
            "deductions": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-calendar-minus',
              name: "Loan Deduction",
              route: "/deductions"
            },

            "sss-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "SSS",
              route: "/sss-table"
            },
            "philHealth-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "PhilHealth",
              route: "/philHealth-table"
            },
            "pagibig-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "Pag-ibig",
              route: "/pagibig-table"
            },
            "tax-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "Tax-Table",
              route: "/tax-table"
            },
            "tax-status-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "Tax Status",
              route: "/tax-status-table"
            },
            "holiday-otrates-table": {
              textColor: 'colorPayroll',
              activeColor: 'activeColorPayroll',
              iconClass: 'fa-list-alt',
              name: "Holiday & OT Rates",
              route: "/holiday-otrates-table"
            },
          };

          for (let i in payrollNav) {
            if (isAdmin) {
              this.navigations.push(payrollNav[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/payroll/process');
              }
            } else {
              if (payroll) {
                for (let n in payroll.sub_modules) {
                  if (i == payroll.sub_modules[n]) {
                    this.navigations.push(payrollNav[i]);
                  }
                }
              }
            }
          }
          break;

        case "timekeeping":
          var timekeeping = (this.usersModules[7] !== undefined) ? this.usersModules[7] : false;
          var tkRoutes = {
            "dashboard-timekeeping": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-th',
              name: "Dashboard",
              route: "/home/dashboard"
            },
            "set-schedules": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-calendar-alt',
              name: "Set Schedule",
              route: "/set/schedule"
            },

            // "upload-schedules": {
            //     textColor: 'colorTimeKeep',
            //     activeColor: 'activeColorTimeKeep',
            //     iconClass: 'fa-calendar-alt',
            //     name: "Set Schedule",
            //     route: "/monthly/schedule/upload"
            // },

            // "hourly-schedules": {
            //   name: "Upload Hourly Schedule",
            //   route: "/hourly/schedule/upload"
            // },
            "upload-timelogs": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-user-clock',
              name: "Upload Timelogs",
              route: "/timelogs/upload"
            },
            "detailed-dtr": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-stopwatch',
              name: "Monthly Detailed TK",
              route: "/detailed/tk"
            },
            "monthly-raw-tk": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-hashtag',
              name: "Monthly Raw Detailed TK",
              route: "/monthly/raw/tk"
            },
            "hourly-detailed-dtr": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-clock',
              name: "Hourly Detailed TK",
              route: "/hourly/detailed/tk"
            },
            "tk-summary": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-file-invoice-dollar',
              name: "TK Summary",
              route: "/tk/summary"
            },
            "tk-pending-request": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-user-clock',
              name: "TK Pending Request",
              route: "/tk/pending/request"
            },
            "tk-request-history": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-calendar-alt',
              name: "TK Request History",
              route: "/tk/request/history"
            },
            ///without logs
            /*
            "binary-upload": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-user-clock',
              name: "Upload Binary (COVID)",
              route: "/binary/upload"
            },
            "binary-detailed-tk": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-stopwatch',
              name: "Detailed Binary (COVID)",
              route: "/binary/detailed/tk"
            },
            "binary-tk-summary": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-file-invoice-dollar',
              name: "Binary Summary (COVID)",
              route: "/binary/tk/summary"
            },
            */
            // "generate-dtr":
            // {
            //   name: "Generate DTR",
            //   route: "/generate/dtr"
            // },
            // "my-timelogs":
            // {
            //   name: "My Calendar",
            //   route: "/my/calendar"
            // },
            "holidays": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-gifts',
              name: "Holidays",
              route: "/holidays"
            },

            "lock-settings": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-lock',
              name: "Lock Settings",
              route: "/lock/settings"
            },
            "periodic-table": {
              textColor: 'colorTimeKeep',
              activeColor: 'activeColorTimeKeep',
              iconClass: 'fa-calendar-alt',
              name: "Periodic Table",
              route: "/periodic/table"
            },
            // "detailed-with-pay": {
            //  iconClass: 'fa-stopwatch',
            //  name: "Detailed TK with Pay",
            //  route: "/detailed/with/pay"
            // },

          };

          for (let i in tkRoutes) {
            if (isAdmin) {
              this.navigations.push(tkRoutes[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/timekeeping/home/dashboard');
              }
            } else {
              if (timekeeping) {
                for (let n in timekeeping.sub_modules) {
                  if (i == timekeeping.sub_modules[n]) {
                    this.navigations.push(tkRoutes[i]);
                  }
                }
              }
            }
          }
          break;

        case "online-forms":
          var onlineForms = (this.usersModules[9] !== undefined) ? this.usersModules[9] : false;
          var onlineFormsNavs = {
            "my-form-approvals": {
              textColor: 'colorOnlineForms',
              activeColor: 'activeColorOnlineForms',
              iconClass: 'fa-user-check',
              name: "My Approvals",
              route: "/my-form-approvals"
            },
            "coe-request-list": {
              textColor: 'colorOnlineForms',
              activeColor: 'activeColorOnlineForms',
              iconClass: 'fa-file-alt',
              name: "COE Request List",
              route: "/coe-request-list"
            },
            "set-form-approvers": {
              textColor: 'colorOnlineForms',
              activeColor: 'activeColorOnlineForms',
              iconClass: 'fa-user-edit',
              name: "Set Approvers",
              route: "/set-form-approvers"
            },
            "employee-form-applications": {
              textColor: 'colorOnlineForms',
              activeColor: 'activeColorOnlineForms',
              iconClass: 'fa-folder-open',
              name: "Employee Applications",
              route: "/employee-form-applications"
          },
          "forms-reports": {
            textColor: 'colorOnlineForms',
            activeColor: 'activeColorOnlineForms',
            iconClass: 'fa-chart-bar',
            name: "Forms Reports",
            route: "/forms-reports"
         },
         "forms-types": {
           textColor: 'colorOnlineForms',
           activeColor: 'activeColorOnlineForms',
           iconClass: 'fa-list-alt',
           name: "Forms",
           route: "/forms-types"
         }
          }

          this.navigations.push({
            textColor: 'colorOnlineForms',
            activeColor: 'activeColorOnlineForms',
            iconClass: 'fa-copy',
            name: "My Applications",
            route: "/my-form-applications"
          }, {
            textColor: 'colorOnlineForms',
            name: "Application Forms",
            route: "/application-forms",
            sub: [{
                textColor: 'colorOnlineForms',
                activeColor: 'activeColorOnlineForms',
                iconClass: 'fa-calendar-times',
                name: "Change of Work Schedule",
                route: "/change-schedule"
              },
              {
                textColor: 'colorOnlineForms',
                activeColor: 'activeColorOnlineForms',
                iconClass: 'fa-history',
                name: "Half Day",
                route: "/halfday"
              },
              {
                textColor: 'colorOnlineForms',
                activeColor: 'activeColorOnlineForms',
                iconClass: 'fa-plane',
                name: "Official Business Trip",
                route: "/business-trip"
              },
              {
                textColor: 'colorOnlineForms',
                activeColor: 'activeColorOnlineForms',
                iconClass: 'fa-stopwatch',
                name: "Overtime",
                route: "/overtime"
              },
              {
                textColor: 'colorOnlineForms',
                activeColor: 'activeColorOnlineForms',
                iconClass: 'fa-history',
                name: "Undertime",
                route: "/undertime"
            },
            {
              textColor: 'colorOnlineForms',
              activeColor: 'activeColorOnlineForms',
              iconClass: 'fa-laptop-house',
              name: "Work From Home",
              route: "/wfh"
           },
           {
             textColor: 'colorOnlineForms',
             activeColor: 'activeColorOnlineForms',
             iconClass: 'fa-file',
             name: "Request COE",
             route: "/request-coe"
           }
            ]
          });

          for (let i in onlineFormsNavs) {
            if (isAdmin) {
              this.navigations.push(onlineFormsNavs[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/online-forms/employee-form-applications');
              }
            } else {
              if (onlineForms) {
                for (let n in onlineForms.sub_modules) {
                  if (i == onlineForms.sub_modules[n]) {
                    this.navigations.push(onlineFormsNavs[i]);
                  }
                }
              }

              if (this.userData.department === 1) {
                if (this.$route.name == "Dashboard") {
                  this.$router.push('/online-forms/employee-form-applications');
                }
              } else {
                if (this.userData.is_approver === 'yes') {
                  if (this.$route.name == "Dashboard") {
                    this.$router.push('/online-forms/my-form-approvals');
                  }
                } else {
                  if (this.$route.name == "Dashboard") {
                    this.$router.push('/online-forms/my-form-applications');
                  }
                }
              }
            }
          }
          break;

        case "settings":
          var settings = (this.usersModules[12] !== undefined) ? this.usersModules[12] : false;
          var settingsRoutes = {
            // "users": {
            //   textColor: 'colorSettings',
            //   activeColor: 'activeColorSettings',
            //   iconClass: 'fa-users-cog',
            //   name: "Users",
            //   route: "/user/list"
            // },
            "module-permissions-access": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Module Permissions Access",
              route: "/module-permissions-access"
            },
            "set-as-approver": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Set As Approver",
              route: "/set-as-approver"
            },
            "region": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Region",
              route: "/region/list"
            },
            "province": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Provinces",
              route: "/province/list"
            },
            "gca": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "GCA",
              route: "/gca/list"
            },
            "coverage": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Coverage",
              route: "/coverage/list"
            },
            "sector": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Sector/Industry",
              route: "/sector/list"
            },
            "mwe-monthly": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "MWE-Monthly",
              route: "/mwe-monthly/list"
            },
            "mwe-daily": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "MWE-Daily",
              route: "/mwe-daily/list"
            },
            "company": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Company",
              route: "/company/list"
            },
            "bank": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Banks",
              route: "/bank/list"
            },
            "ranks": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Ranks",
              route: "/rank/list"
            },
            "course": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Courses",
              route: "/course/list"
            },
            "account": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Accounts",
              route: "/account/list"
            },
            "payclass": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Payclass",
              route: "/payclass/list"
            },
            "location": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Locations",
              route: "/location/list"
            },
            "position": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Positions",
              route: "/position/list"
            },
            "civil-status": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Civil Status",
              route: "/civil-status/list"
            },
            "cost-center": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Cost Center",
              route: "/cost-center/list"
            },
            "tax-status": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Tax Status",
              route: "/tax-status/list"
            },
            "department": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Departments",
              route: "/department/list"
            },
            "accountability": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Accountability",
              route: "/accountability/list"
            },
            "payment-mode": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Payment Mode",
              route: "/payment-mode/list"
            },
            "status": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Employee Status",
              route: "/status/list"
            },
            "division-head": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Division Head",
              route: "/division-head/list"
            },
            "immediate-head": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Immediate Head",
              route: "/immediate-head/list"
            },
            "employee-status": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Employment Status",
              route: "/employment-status/list"
            },
            "deactivation-reasons": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Deactivation Reasons",
              route: "/deactivation-reasons/list"
            },
            "relationship": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Person to Notify Relationship",
              route: "/relationship/list"
            },
            "deduction": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Deduction",
              route: "/deduction/list"
            },
            "adjustment": {
              textColor: 'colorSettings',
              activeColor: 'activeColorSettings',
              iconClass: 'fa-users-cog',
              name: "Adjustment",
              route: "/adjustment/list"
            },
          };

          for (let i in settingsRoutes) {
            if (isAdmin) {
              this.navigations.push(settingsRoutes[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/settings/module-permissions-access');
              }
            } else {
              if (settings) {
                for (let n in settings.sub_modules) {
                  if (i == settings.sub_modules[n]) {
                    this.navigations.push(settingsRoutes[i]);
                  }
                }
              }
            }
            // if (this.$route.name == "Dashboard") {
            //   this.$router.push('/settings/module-permissions-access');
            // }
          }
          break;

        case "leaves":
          var leaveForms = (this.usersModules[3] !== undefined) ? this.usersModules[3] : false;
          var leaveFormsNavs = {
            "my-leave-approvals": {
              textColor: 'colorLeave',
              activeColor: 'activeColorLeave',
              iconClass: 'fa-user-check',
              name: "My Approvals",
              route: "/my-leave-approvals"
            },
            "set-leave-approvers": {
              textColor: 'colorLeave',
              activeColor: 'activeColorLeave',
              iconClass: 'fa-user-edit',
              name: "Set Approvers",
              route: "/set-leave-approvers"
            },
            "employee-leave-applications": {
              textColor: 'colorLeave',
              activeColor: 'activeColorLeave',
              iconClass: 'fa-folder-open',
              name: "Employee Applications",
              route: "/employee-leave-applications"
            },
            "employee-leave-credits": {
              textColor: 'colorLeave',
              activeColor: 'activeColorLeave',
              iconClass: 'fa-list',
              name: "Employee Leave Credits",
              route: "/employee-leave-credits"
            },
            "special-leave-applications": {
              textColor: 'colorLeave',
              activeColor: 'activeColorLeave',
              iconClass: 'fa-file-contract',
              name: "Special Leave Applications",
              route: "/special-leave-applications"
          },
          "leaves-reports": {
            textColor: 'colorLeave',
            activeColor: 'activeColorLeave',
            iconClass: 'fa-chart-bar',
            name: "Leave Reports",
            route: "/leave-reports"
        },
        "remaining-leaves": {
             textColor: 'colorLeave',
             activeColor: 'activeColorLeave',
             iconClass: 'fa-file-contract',
             name: "Remaining Leaves",
             route: "/remaining-leaves"
         },
         "employee-leave-history": {
           textColor: 'colorLeave',
           activeColor: 'activeColorLeave',
           iconClass: 'fa-history',
           name: "Employee Leave History",
           route: "/employee-leave-history"
       },
        // "leaves-reports":{
        //     textColor: 'colorLeave',
        //     name: "Leave Reports",
        //     route: "/leave-reports",
        //     sub: [{
        //             textColor: 'colorLeave',
        //             activeColor: 'activeColorLeave',
        //             iconClass: 'fa-chart-bar',
        //             name: "Leave Summary",
        //             route: "/leave-reports"
        //         },
        //         {
        //             textColor: 'colorLeave',
        //             activeColor: 'activeColorLeave',
        //             iconClass: 'fa-history',
        //             name: "Remaining Leaves",
        //             route: "/remaining-leaves"
        //         }
        //         ]
        //     }
          }

          this.navigations.push({
            textColor: 'colorLeave',
            activeColor: 'activeColorLeave',
            iconClass: 'fa-copy',
            name: "My Applications",
            route: "/my-leave-applications"
          }, {
            textColor: 'colorLeave',
            activeColor: 'activeColorLeave',
            iconClass: 'fa-file-alt',
            name: "Application Form",
            route: "/application-form"
          }, {
            textColor: 'colorLeave',
            activeColor: 'activeColorLeave',
            iconClass: 'fa-list-ol',
            name: "My Leave Credits",
            route: "/my-leave-credits"
          }
      );

          for (let i in leaveFormsNavs) {
            if (isAdmin) {
              this.navigations.push(leaveFormsNavs[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/leaves/employee-leave-applications');
              }
            } else {
              if (leaveForms) {
                for (let n in leaveForms.sub_modules) {
                  if (i == leaveForms.sub_modules[n]) {
                    this.navigations.push(leaveFormsNavs[i]);
                  }
                }
              }

              if (this.userData.department === 1) {
                if (this.$route.name == "Dashboard") {
                  this.$router.push('/leaves/employee-leave-applications');
                }
              } else {
                if (this.userData.is_approver === 'yes') {
                  if (this.$route.name == "Dashboard") {
                    this.$router.push('/leaves/my-leave-approvals');
                  }
                } else {
                  if (this.$route.name == "Dashboard") {
                    this.$router.push('/leaves/my-leave-applications');
                  }
                }
              }
            }
          }
          break;

        case "recruitment":
          var recruitment = (this.usersModules[5] !== undefined) ? this.usersModules[5] : false;
          var recruitmentNav = {
              "dashboard-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Dashboard",
             route: "/dashboard-list",
           },
           "pool-list2": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Pool List",
             route: "/pool-list2",
           },
           "exam-result-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Exam List",
             route: "/exam-result-list",
           },
           "final-interview-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Final Interview List",
             route: "/final-interview-list",
           },
           "training-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Training List",
             route: "/training-list",
           },
           "hired-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Hired List",
             route: "/hired-list",
           },
           //06022022
           "dump-list": {
             textColor: "colorRecruitment",
             activeColor: "activeColorRecruitment",
             iconClass: "fa-list-ol",
             name: "Dump List",
             route: "/dump-list",
           },
           //ruben :rehire 13-06-2023
          "re-hire": {
           textColor: "colorRecruitment",
           activeColor: "activeColorRecruitment",
           iconClass: "fa-list-ol",
           name: "Re-Hire",
           route: "/re-hire",
          },
          //ruben :sms-blast 22-06-2023
          "sms-blast": {
          textColor: "colorRecruitment",
          activeColor: "activeColorRecruitment",
          iconClass: "fa-list-ol",
          name: "SMS-Blast",
          route: "/sms-blast",
          sub: [{
             textColor: 'colorRecruitment',
             activeColor: 'activeColorRecruitment',
             iconClass: 'fa-list-ol',
             name: "SMS Receiver List",
             route: "/sms-blast"
           },
           {
             textColor: 'colorRecruitment',
             activeColor: 'activeColorRecruitment',
             iconClass: 'fa-list-ol',
             name: "SMS Template",
             route: "/sms-template"
           }],
         },

          }

          for (let i in recruitmentNav) {
            this.navigations.push(recruitmentNav[i]);
          }

          if (this.$route.name == "Dashboard") {
             this.$router.push("/recruitment/dashboard-list");
          }
          break;

        case "announcement":
          var announcement = (this.usersModules[10] !== undefined) ? this.usersModules[10] : false;
          var announcementNav = {
            "admin-dashboard": {
              textColor: 'colorAnnouncement',
              activeColor: 'activeColorAnnouncement',
              iconClass: 'fa-th',
              name: "Admin Dashboard",
              route: "/admin-dashboard"
            },
          };

          this.navigations.push({
            textColor: 'colorAnnouncement',
            activeColor: 'activeColorAnnouncement',
            iconClass: 'fa-th',
            name: "Dashboard",
            route: "/announcement-list"
          });

          for (let i in announcementNav) {
            if (isAdmin) {
              this.navigations.push(announcementNav[i]);
              if (this.$route.name == "Dashboard") {
                this.$router.push('/announcement/admin-dashboard');
              }
            } else {
              if (announcement) {
                for (let n in announcement.sub_modules) {
                  if (i == announcement.sub_modules[n]) {
                    this.navigations.push(announcementNav[i]);
                  }
                }
              }

              if (this.$route.name == "Dashboard") {
                this.$router.push('/announcement/announcement-list');
              }
            }
          }
         break;

        case "database":
            var databaseModules = (this.usersModules[184] !== undefined) ? this.usersModules[184] : false; //live
            //var databaseModules = (this.usersModules[145] !== undefined) ? this.usersModules[145] : false;
            var databaseNav = {
              "database-dashboard": {
                textColor: 'colorDatabase',
                activeColor: 'activeColorDatabase',
                iconClass: 'fa-th',
                name: "Dashboard",
                route: "/database-dashboard"
              },
              "database-masterlist": {
                textColor: 'colorDatabase',
                activeColor: 'activeColorDatabase',
                iconClass: 'fa-list',
                name: "Materlist",
                route: "/database-masterlist"
              },
              "support-department": {
               textColor: "colorDatabase",
               activeColor: "activeColorDatabase",
               iconClass: "fa-file",
               name: "Support",
               route: "/support-department",
               sub: [
                     {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "HR",
                     route: "/hr-files"
                    },
                    {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "IT",
                     route: "/it-files"
                  },
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "QA",
                     route: "/qa-files"
                  },
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "ADMIN",
                     route: "/admin-files"
                  }
               ],
               // sub: {
               //       "hr-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "HR",
               //       route: "/hr-files"
               //      },
               //      "it-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "IT",
               //       route: "/it-files"
               //    },
               //    "qa-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "QA",
               //       route: "/qa-files"
               //    },
               //    "admin-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "ADMIN",
               //       route: "/admin-files"
               //    }
               // },
              },
              "food-group-department": {
               textColor: "colorDatabase",
               activeColor: "activeColorDatabase",
               iconClass: "fa-file",
               name: "Food Group",
               route: "/food-group-department",
               sub: [
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "JOLLIBEE",
                     route: "/jollibee-files"
                    },
                 {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "GREENWICH",
                     route: "/greenwich-files"
                  },
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "AMBER",
                     route: "/amber-files"
                  }
               ],
               // sub: {
               //    "jollibee-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "JOLLIBEE",
               //       route: "/jollibee-files"
               //      },
               //   "greenwich-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "GREENWICH",
               //       route: "/greenwich-files"
               //    },
               //    "amber-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "AMBER",
               //       route: "/amber-files"
               //    }
               // },
              },
              "telco-department": {
               textColor: "colorDatabase",
               activeColor: "activeColorDatabase",
               iconClass: "fa-file",
               name: "Telco",
               route: "/telco-department",
               sub: [
                     {
                        textColor: 'colorDatabase',
                        activeColor: 'activeColorDatabase',
                        iconClass: 'fa-folder',
                        name: "PLDT",
                        route: "/pldt-files"
                       },
                    {
                        textColor: 'colorDatabase',
                        activeColor: 'activeColorDatabase',
                        iconClass: 'fa-folder',
                        name: "SMART",
                        route: "/smart-files"
                     },
                  {
                        textColor: 'colorDatabase',
                        activeColor: 'activeColorDatabase',
                        iconClass: 'fa-folder',
                        name: "DITO",
                        route: "/dito-files"
                     }
                  ]
               // sub: {
               //       "pldt-files":{
               //          textColor: 'colorDatabase',
               //          activeColor: 'activeColorDatabase',
               //          iconClass: 'fa-folder',
               //          name: "PLDT",
               //          route: "/pldt-files"
               //         },
               //      "smart-files":{
               //          textColor: 'colorDatabase',
               //          activeColor: 'activeColorDatabase',
               //          iconClass: 'fa-folder',
               //          name: "SMART",
               //          route: "/smart-files"
               //       },
               //    "dito-files":{
               //          textColor: 'colorDatabase',
               //          activeColor: 'activeColorDatabase',
               //          iconClass: 'fa-folder',
               //          name: "DITO",
               //          route: "/dito-files"
               //       }
               //    },
              },
              "diego-team": {
               textColor: "colorDatabase",
               activeColor: "activeColorDatabase",
               iconClass: "fa-file",
               name: "Diego's Team",
               route: "/diego-team",
               sub: [
                     {
                        textColor: 'colorDatabase',
                        activeColor: 'activeColorDatabase',
                        iconClass: 'fa-folder',
                        name: "ACFE",
                        route: "/acfe-files"
                    },
                    {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "HI-PRECISION",
                     route: "/hi-precision-files"
                  },
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "GALLEON",
                     route: "/galleon-files"
                  },
                  {
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "TOYOTA",
                     route: "/toyota-files"
                  }
               ],
               // sub: {
               //       "acfe-files":{
               //          textColor: 'colorDatabase',
               //          activeColor: 'activeColorDatabase',
               //          iconClass: 'fa-folder',
               //          name: "ACFE",
               //          route: "/acfe-files"
               //      },
               //      "hi-precision-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "HI-PRECISION",
               //       route: "/hi-precision-files"
               //    },
               //    "galleon-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "GALLEON",
               //       route: "/galleon-files"
               //    },
               //    "toyota-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "TOYOTA",
               //       route: "/toyota-files"
               //    }
               // },
              },
              "bravo-team": {
               textColor: "colorDatabase",
               activeColor: "activeColorDatabase",
               iconClass: "fa-file",
               name: "Bravo's Team",
               route: "/bravo-team",
               sub: [{
                     textColor: 'colorDatabase',
                     activeColor: 'activeColorDatabase',
                     iconClass: 'fa-folder',
                     name: "DLG",
                     route: "/dlg-files"
                  },]
               // sub: {
               //       "dlg-files":{
               //       textColor: 'colorDatabase',
               //       activeColor: 'activeColorDatabase',
               //       iconClass: 'fa-folder',
               //       name: "DLG",
               //       route: "/dlg-files"
               //    }
               // },
              },
            };

            for (let i in databaseNav) {
              if (isAdmin) {
                this.navigations.push(databaseNav[i]);
                if (this.$route.name == "Dashboard") {
                  this.$router.push('/database/database-dashboard');
                }
              } else {

                if (databaseModules) {
                   //for third sub
                   /*
                  for (let third in databaseModules.third_sub) {

                    if(i == third){ //if the key is same

                       if(databaseModules.third_sub[third].length > 0){

                          for (let thirdLay in databaseNav[i].sub) { //from static
                              if(!databaseModules.third_sub[third].includes(thirdLay)){
                                 delete databaseNav[i].sub[thirdLay] //delete if not include
                              }
                           }
                        }
                     }
                  }
                  */
                  //end of third sub

                  for (let n in databaseModules.sub_modules) {
                    if (i == databaseModules.sub_modules[n]) {
                      this.navigations.push(databaseNav[i]);
                    }
                  }

                }

                if (this.$route.name == "Dashboard") {
                  this.$router.push('/database/database-dashboard');
                }
              }
            }
        break;

        case "payslip":
            var payslip = (this.usersModules[8] !== undefined) ? this.usersModules[8] : false;
            var payslipNav = {
              "payslip-dashboard": {
                textColor: 'colorDatabase',
                activeColor: 'activeColorDatabase',
                iconClass: 'fa-th',
                name: "Dashboard",
                route: "/payslip-dashboard"
              },
            };

            for (let i in payslipNav) {
              if (isAdmin) {
                this.navigations.push(payslipNav[i]);
                if (this.$route.name == "Dashboard") {
                  this.$router.push('/payslip/payslip-dashboard');
                }
              } else {
                if (payslip) {
                  for (let n in payslip.sub_modules) {
                    if (i == payslip.sub_modules[n]) {
                      this.navigations.push(payslipNav[i]);
                    }
                  }
                }

                if (this.$route.name == "Dashboard") {
                  this.$router.push('/payslip/payslip-dashboard');
                }
              }
            }
        break;

        case "backend":
          var backend = (this.usersModules[11] !== undefined) ? this.usersModules[11] : false;
          var backendNav = {
            "backend-list": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-th',
              name: "Dashboard",
              route: "/backend-list"
            },
            "vertical-summary": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "Vertical Detailed",
              route: "/vertical-summary"
            },
            "detailed-payslip": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-newspaper',
              name: "Detailed Payslip",
              route: "/detailed-payslip"
            },
            "sss-employee-contribution": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "SSS Employee Contribution",
              route: "/sss-employee-contribution"
            },
            "philhealth-employee-contribution": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "Philhealth Employee Contribution",
              route: "/philhealth-employee-contribution"
            },
            "pagibig-employee-contribution": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "Pag-ibig Employee Contribution",
              route: "/pagibig-employee-contribution"
            },
            "alphalist": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "Alphalist",
              route: "/alphalist"
            },
            "total-gov-contribution": {
              textColor: 'colorBackend',
              activeColor: 'activeColorBackend',
              iconClass: 'fa-list-alt',
              name: "Total Gov Contribution",
              route: "/total-gov-contribution"
            },
          };

          for (let i in backendNav) {
            if (isAdmin) {
              this.navigations.push(backendNav[i]);
            } else {
              if (backend) {
                for (let n in backend.sub_modules) {
                  if (i == backend.sub_modules[n]) {
                    this.navigations.push(backendNav[i]);
                  }
                }
              }
            }

            if (this.$route.name == "Dashboard") {
              this.$router.push('/backend/backend-list');
            }
          }
          break;

          // ruben::08/08/2023
         case "pantry":
           var pantry = (this.usersModules[135] !== undefined) ? this.usersModules[135] : false;
           var pantryNav = {
             "sync-employees": {
               textColor: 'colorPantry',
               activeColor: 'activeColorPantry',
               iconClass: 'fa-copy',
               name: "Search or Sync Employees",
               route: "/sync-employees"
             },
             "regular-employee-report": {
               textColor: 'colorPantry',
               activeColor: 'activeColorPantry',
               iconClass: 'fa-file-word',
               name: "Regular Employee Report",
               route: "/regular-employee-report"
             },
             "trainee-employees": {
               textColor: 'colorPantry',
               activeColor: 'activeColorPantry',
               iconClass: 'fa-users',
               name: "Trainee Employees",
               route: "/trainee-employees"
             },
             "trainee-employee-report": {
               textColor: 'colorPantry',
               activeColor: 'activeColorPantry',
               iconClass: 'fa-file-excel',
               name: "Trainee Employee Report",
               route: "/trainee-employee-report"
             },
           };

           for (let i in pantryNav) {
            if (isAdmin) {
              this.navigations.push(pantryNav[i]);
            } else {
              if (pantry) {
                for (let n in pantry.sub_modules) {
                  if (i == pantry.sub_modules[n]) {
                    this.navigations.push(pantryNav[i]);
                  }
                }
              }
            }

            if (this.$route.name == "Dashboard") {
              this.$router.push('/pantry/sync-employees');
            }
          }
           break;

           // mark::11/25/2024
         case "finance":
           var finance = (this.usersModules[142] !== undefined) ? this.usersModules[142] : false;
           var financeNav = {
             "account-receivable": {
               textColor: 'colorFinance',
               activeColor: 'activeColorFinance',
               iconClass: 'fa-file-signature',
               name: "Account Receivable",
               route: "/account-receivable"
             },
             "account-payable": {
               textColor: 'colorFinance',
               activeColor: 'activeColorFinance',
               iconClass: 'fa-handshake',
               name: "Account Payable",
               route: "/account-payable"
             },
             "finance-report": {
              textColor: "colorFinance",
              activeColor: "activeColorFinance",
              iconClass: "fa-file",
              name: "Reports",
              route: "/ar-report",
              sub: [{
                 textColor: 'colorFinance',
                 activeColor: 'activeColorFinance',
                 iconClass: 'fa-file-excel',
                 name: "AR Report",
                 route: "/ar-report"
                },
                {
                 textColor: 'colorFinance',
                 activeColor: 'activeColorFinance',
                 iconClass: 'fa-file-excel',
                 name: "AP Report",
                 route: "/ap-report"
                }],
             },
           };

           for (let i in financeNav) {
           if (isAdmin) {
             this.navigations.push(financeNav[i]);
           } else {
             if (finance) {
                for (let n in finance.sub_modules) {
                  if (i == finance.sub_modules[n]) {
                    this.navigations.push(financeNav[i]);
                  }
                }
             }
           }

           if (this.$route.name == "Dashboard") {
             this.$router.push('/finance/account-receivable');
           }
          }
           break;

      }
    }
  }
};
</script>
<style scoped>
.qExpandItem {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.module-header {
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.menu-header {
  color: #FFF;
  text-shadow: -1px -1px #888,
    1px -1px 0 #888,
    -1px 1px 0 #888,
    1px 1px 0 #888;
}

.colorLeave {
  color: #FFA000;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorLeave {
  color: white;
  background-color: #FFA000;
  text-shadow: none;
}

.activeColorLeave::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorEmployee {
  color: #1E88E5;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorEmployee {
  color: white;
  background-color: #1E88E5;
  text-shadow: none;
}

.activeColorEmployee::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorPayroll {
  color: #2E7D32;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorPayroll {
  color: white;
  background-color: #2E7D32;
  text-shadow: none;
}

.activeColorPayroll::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorTimeKeep {
  color: #546E7A;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorTimeKeep {
  color: white;
  background-color: #546E7A;
  text-shadow: none;
}

.activeColorTimeKeep::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorOnlineForms {
  color: #E53935;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorOnlineForms {
  color: white;
  background-color: #E53935;
  text-shadow: none;
}

.activeColorOnlineForms::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorSettings {
  color: #212121;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorSettings {
  color: white;
  background-color: #212121;
  text-shadow: none;
}

.activeColorSettings::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorRecruitment {
  color: #3F51B5;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorRecruitment {
  color: white;
  background-color: #3F51B5;
  text-shadow: none;
}

.activeColorRecruitment::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorAnnouncement {
  color: #E65100;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorAnnouncement {
  color: white;
  background-color: #E65100;
  text-shadow: none;
}

.activeColorAnnouncement::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorBackend {
  color: #827717;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorBackend {
  color: white;
  background-color: #827717;
  text-shadow: none;
}

.activeColorBackend::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorSettings {
  color: #d50000;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorSettings {
  color: white;
  background-color: #d50000;
  text-shadow: none;
}

.activeColorSettings::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorPantry {
  color: #c51162;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorPantry {
  color: white;
  background-color: #c51162;
  text-shadow: none;
}

.activeColorPantry::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorFinance {
  color: #db4712;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorFinance{
  color: white;
  background-color: #db4712;
  text-shadow: none;
}

.activeColorFinance::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.colorDatabase {
  color: #0e47a1;
  text-shadow: -1px -1px #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.activeColorDatabase{
  color: white;
  background-color: #0e47a1;
  text-shadow: none;
}

.activeColorDatabase::after {
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
  content: "\f0da";
  margin-top: 3px;
}

.q-bt {
  background-color: "black";
}

.hitler {
  background: url('../assets/office-background-3.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.sidebg {
  background: url('../assets/bgcrop-2.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /*background-attachment: fixed;*/
  opacity: 1;
  top: 90px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

/*START:: FOR NOTIFICATION*/
.notif-title {
  margin-left: 15px;
  margin-top: 15px;
}

.item-list {
  margin: 0;
  font-size: 18px;
  line-height: 1.2em;
}

.item-dt {
  line-height: 2em;
  margin-bottom: 0;
}

/*END:: FOR NOTIFICATION*/
</style>
