import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'
import mixins from './mixins'

Vue.use(Router)

import Dashboard from './views/Dashboard.vue';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import NotAllowed from './views/NotAllowed.vue';

/*** Employee Management **/
import EmployeeDashboard from './components/employee-management/Dashboard.vue';
import ModulePermissions from './components/employee-management/ModulePermissions.vue';
import CreateEmployee from './components/employee-management/CreateEmployee.vue';
import ListofEmployee from './components/employee-management/ListEmployee.vue';
import ListofHoldEmployee from './components/employee-management/HoldEmployees.vue';
import ListofInactiveEmployee from './components/employee-management/InactiveEmployees.vue';
import RoleAccessPermission from './components/employee-management/RoleAccessPermission.vue';
/*** End Employee Management **/

/*** Online Forms **/
import MyApprovals_Form from './components/online-forms/MyApprovals_Form.vue';
import COERequestList from './components/online-forms/COERequestList.vue';
import MyApplications_Form from './components/online-forms/MyApplications_Form.vue';
import ChangeSchedule from './components/online-forms/ChangeSchedule.vue';
import Halfday from './components/online-forms/Halfday.vue';
import OfficialBusinessTrip from './components/online-forms/OfficialBusinessTrip.vue';
import Overtime from './components/online-forms/Overtime.vue';
import Undertime from './components/online-forms/Undertime.vue';
import WorkFromHome from './components/online-forms/WorkFromHome.vue';
import COERequest from './components/online-forms/COERequest.vue';
import SetApprovers_Form from './components/online-forms/SetApprovers_Form.vue';
import EmployeeApplications_Form from './components/online-forms/EmployeeApplications_Form.vue';
/*** End Online Forms **/

/*** Timekeeping **/
import TimekeepingDashboard from './components/timekeeping/Dashboard.vue';
import SetByDepartmentSchedule from './components/timekeeping/SetByDepartmentSchedule.vue';

import MonthlyUploadschedule from './components/timekeeping/2SetSchedule.vue';

//import HourlyUploadschedule from './components/timekeeping/HourlySchedule.vue';
import UploadTimelogs from './components/timekeeping/SetTimelogs.vue';
import DetailedTK from './components/timekeeping/DetailedTK.vue';
import DetailedRawTK from './components/timekeeping/DetailedRawTK.vue';
import HourlyDetailedTK from './components/timekeeping/HourlySchedule.vue';
import TKSummary from './components/timekeeping/TKSummary.vue';
import TKPendingRequest from './components/timekeeping/TKPendingRequest.vue';
import TKPendingRequestHistory from './components/timekeeping/TKPendingRequestHistory.vue';
import GenerateDTR from './components/timekeeping/GenerateDTR.vue';
import MySchedules from './components/timekeeping/MySchedule.vue';
import MyCalendar from './components/timekeeping/MyCalendar.vue';
import Holidays from './components/timekeeping/Holidays.vue';
import LoanDeductions from './components/timekeeping/LoanDeductions.vue';

import Adjustments from './components/timekeeping/Adjustments.vue';
import LockSettings from './components/timekeeping/LockSettings.vue';
import BinarySchedule from './components/binary/BinarySchedule.vue';
import BinaryDetailedTK from './components/binary/BinaryDetailedTK.vue';
import BinarySummaryTK from './components/binary/BinarySummaryTK.vue';

import PeriodicTable from './components/timekeeping/PeriodicTable.vue';
/*** End Timekeeping **/

/*** Payroll **/
import ViewEmployees from './components/payroll/ViewEmployee.vue';
import ProcessPayroll from './components/payroll/ProcessPayroll.vue';
/*** End Payroll **/

/** Leave Module **/
import MyApprovals_Leave from './components/leaves/MyApprovals_Leave.vue';
import MyApplications_Leave from './components/leaves/MyApplications_Leave.vue';
import MyLeaveCredits from './components/leaves/MyLeaveCredits.vue';
import LeaveApplication from './components/leaves/LeaveApplication.vue';
import SetApprovers_Leave from './components/leaves/SetApprovers_Leave.vue';
import EmployeeApplications_Leave from './components/leaves/EmployeeApplications_Leave.vue';
import EmployeeLeaveCredits from './components/leaves/EmployeeLeaveCredits.vue';
import SpecialLeaveApplications from './components/leaves/SpecialLeaveApplications.vue';
/** End Leave Module **/

/** Reports Module **/
import LeaveApplicationReports from './components/reports/LeavesReports.vue';
import RemainingLeaveApplicationReports from './components/reports/RemainingLeavesReports.vue';
import EmployeeLeavesHistory from './components/reports/EmployeeLeavesHistory.vue';

import FormsApplicationReports from './components/reports/FormsReports.vue';
import FormsTypes from './components/online-forms/Forms.vue';
/** End Reports Module **/


/** Recruitment **/
// import ListRecruitment from './components/recruitment/ListRecruitment.vue';
// import ForAppointment from './components/recruitment/ForAppointment.vue';
import ForPoolList from './components/recruitment/ForPool.vue';
import ForDashboard from './components/recruitment/ForDashboard.vue'; // 09-28-2022
import ForPoolList2 from './components/recruitment/ForPool2.vue'; // 05-27-2022
import ForExamList from './components/recruitment/ForExam.vue';
import ForExamResultList from './components/recruitment/ForExamResult.vue';
import ForInitialInterview from './components/recruitment/ForInitialInterview.vue';
import ForFinalExam from './components/recruitment/ForFinalExam.vue';
import ForFinalInterview from './components/recruitment/ForFinalInterview.vue';
import ForTrainingList from './components/recruitment/ForTraining.vue'; // new
import ForHiredList from './components/recruitment/ForHired.vue';
import ForDumpList from './components/recruitment/ForDumpList.vue';

import ReHireList from './components/recruitment/ReHireList.vue'; //ruben :rehire 13-06-2023
import ReHireEmployee from './components/employee-management/ReHireEmployee.vue'; //ruben :rehire 13-06-2023
import ExitInterview from './components/employee-management/ExitInterview.vue'; //ruben :rehire 13-06-2023
import ExitInterviewReport from './components/employee-management/ExitInterviewReport.vue';
import SmsBlast from './components/recruitment/SmsBlast.vue'; //ruben :sms-blast 22-06-2023
import SmsTemplate from './components/recruitment/SmsTemplate.vue'; //ruben :sms-blast 22-06-2023

// import ForOrientation from './components/recruitment/ForOrientation.vue';
// import ForTraining from './components/recruitment/ForTraining.vue';
import EmploymentApplication from './components/recruitment/EmploymentApplication.vue';
import ForReviewEmployees from './components/employee-management/ForReviewEmployees.vue'; // 06072022
/** End Recruitment **/
import EndorseApplication from './components/employee-management/EndorseApplication.vue'; // 01092023
import NewApplication from './components/employee-management/NewApplication.vue'; // 01092023
import EndorseReports from './components/employee-management/EndorseReports.vue'; // 01092023

/** Admin Settings **/
import UserList from './components/admin-settings/Users.vue';
import StatusList from './components/admin-settings/Status.vue';
import BankList from './components/admin-settings/Banks.vue';
import RankList from './components/admin-settings/Ranks.vue';
import CourseList from './components/admin-settings/Course.vue';
import AccountList from './components/admin-settings/Accounts.vue';
import PayclassList from './components/admin-settings/Payclass.vue';
import LocationList from './components/admin-settings/Locations.vue';
import PositionList from './components/admin-settings/Position.vue';
import CivilStatusList from './components/admin-settings/Civil-Status.vue';
import CostCenterList from './components/admin-settings/Cost-Center.vue';
import TaxStatusList from './components/admin-settings/Tax-Status.vue';
import DepartmentList from './components/admin-settings/Departments.vue';
import AccountabilityList from './components/admin-settings/Accountability.vue';
import PaymentModeList from './components/admin-settings/Payment-Mode.vue';
import EmploymentStatusList from './components/admin-settings/Employment-Status.vue';
import ImmediateHeadList from './components/admin-settings/ImmediateHead.vue';
import DivisionHeadList from './components/admin-settings/DivisionHead.vue';
import DeactivationReasonList from './components/admin-settings/Deactivation-Reason.vue';
import RelationshipList from './components/admin-settings/Person-to-notif-relationship.vue';
import RegionList from './components/admin-settings/Region.vue';
import ProvinceList from './components/admin-settings/Provinces.vue';
import CoverageList from './components/admin-settings/Coverage.vue';
import SectorList from './components/admin-settings/Sector.vue';
import GCAList from './components/admin-settings/GCASettings.vue';
import CompanyList from './components/admin-settings/Company.vue';
import MWEMonthlyList from './components/admin-settings/MWEMonthly.vue';
import MWEDailyList from './components/admin-settings/MWEDaily.vue';
import Deduction from './components/admin-settings/Deduction.vue';
import Adjustment from './components/admin-settings/Adjustment.vue';
import ModulePermissionsAccess from './components/admin-settings/ModulePermissionsAccess.vue';
import SetAsApprover from './components/admin-settings/SetAsApprover.vue';
import EmployeeLogs from './components/admin-settings/EmployeeLogs.vue'; //05-27-2022
/** End Admin Settings **/

/** Start Announcement **/
import AnnouncementDashboard from './components/announcement/Dashboard.vue';
import AdminDashboard from './components/announcement/AdminDashboard.vue';
/** End Announcement **/

/** Start Database **/
import DatabaseDashboard from './components/manage-database/Dashboard.vue';
import DatabaseMasterlist from './components/manage-database/Masterlist.vue';
import HRFiles from './components/manage-database/HRFiles.vue';
import ITFiles from './components/manage-database/ITFiles.vue';
import QAFiles from './components/manage-database/QAFiles.vue';
import ADMINFiles from './components/manage-database/ADMINFiles.vue';
import JOLLIBEEFiles from './components/manage-database/JOLLIBEEFiles.vue';
import GREENWICHFiles from './components/manage-database/GREENWICHFiles.vue';
import AMBERFiles from './components/manage-database/AMBERFiles.vue';
import PLDTFiles from './components/manage-database/PLDTFiles.vue';
import SMARTFiles from './components/manage-database/SMARTFiles.vue';
import DITOFiles from './components/manage-database/DITOFiles.vue';
import ACFEFiles from './components/manage-database/ACFEFiles.vue';
import HIPRECISIONFiles from './components/manage-database/HIPRECISIONFiles.vue';
import GALLEONFiles from './components/manage-database/GALLEONFiles.vue';
import TOYOTAFiles from './components/manage-database/TOYOTAFiles.vue';
import DLGFiles from './components/manage-database/DLGFiles.vue';
/** End Database **/

/** Start Payslip **/
import PayslipDashboard from './components/payslip/Dashboard.vue';
/** End Payslip **/

/** Start Announcement **/
import BackendDashboard from './components/backend/Dashboard.vue';
import VerticalDetailed from './components/backend/VerticalDetailed.vue';
import DetailedPayslip from './components/backend/DetailedPayslip.vue';
import SSSEmployeeContribution from './components/backend/SSSEmployeeContribution.vue';
import PhilHealthEmployeeContribution from './components/backend/PhilHealthEmployeeContribution.vue';
import PagibigEmployeeContribution from './components/backend/PagibigEmployeeContribution.vue';
import Alphalist from './components/backend/Alphalist.vue';
import TotalGovContribution from './components/backend/TotalGovContribution.vue';

import SSSTable from './components/backend/SSSTable.vue';
import PhilHealth from './components/backend/PhilHealth.vue';
import Pagibig from './components/backend/Pagibig.vue';
import TaxTable from './components/backend/TaxTable.vue';
import TaxStatus from './components/backend/TaxStatus.vue';
import HolidayOTRates from './components/backend/HolidayOTRates.vue';
/** End Announcement **/

// Start Pantry
import EmployeeList from './components/pantry/EmployeeList.vue';
import RegularEmployeeReport from './components/pantry/RegularEmployeeReport.vue';
import TraineeEmployeeReport from './components/pantry/TraineeEmployeeReport.vue';
import TraineeEmployeeList from './components/pantry/TraineeList.vue';

//Start Finance
import AccountReceivable from './components/finance/AccountReceivable.vue';
import AccountPayable from './components/finance/AccountPayable.vue';
import AccountReceivableReport from './components/finance/AccountReceivableReport.vue';
import AccountPayableReport from './components/finance/AccountPayableReport.vue';
//PayrollHistory
import PayrollHistory from "@/components/payroll/PayrollHistory";

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      meta: { title: 'Main Dashboard' },
      path: "/",
      redirect: "/home",
    },
    {
      meta: { title: 'Main Dashboard' },
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      meta: { title: 'Login' },
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      meta: { title: 'Page 404' },
      path: '/not-allowed',
      name: 'NotAllowed',
      component: NotAllowed
    },
    {
      meta: { title: 'Employment Application' },
      path: 'employment-application',
      name: 'EmploymentApplication',
      component: EmploymentApplication
    },
    {
      path: '/:module',
      name: 'Dashboard',
      component: Dashboard,
      children: [
        /*** Employee Management **/
        {
          meta: { title: 'Dashboard' },
          path: 'dashboard',
          component: EmployeeDashboard
        },
        {
          meta: { title: 'Role Access Permission' },
          path: 'role-access-permissions',
          component: RoleAccessPermission
        },
        {
          meta: { title: 'Module Permission' },
          path: 'module-permissions',
          name: 'ModulePermissions',
          component: ModulePermissions
        },
        {
          meta: { title: 'Create Employee' },
          path: 'create',
          component: CreateEmployee,
        },
        {
          meta: { title: 'List of Employees' },
          path: 'list',
          component: ListofEmployee
        },
        {
          meta: { title: 'List of Inactive Employees' },
          path: 'inactive-employees',
          component: ListofInactiveEmployee
        },
        {
          meta: { title: 'List of Hold Employees' },
          path: 'hold-employees',
          component: ListofHoldEmployee
        },
        {
          meta: { title: 'Endorse Employees' },
          path: 'endorse-employee',
          component: EndorseApplication
        }, // 01092023
        {
          meta: { title: 'New Employees' },
          path: 'new-employee',
          component: NewApplication
        },
        {
          meta: { title: 'Endorse Reports' },
          path: 'endorse-reports',
          component: EndorseReports
        },
        //ruben::rehire 15-06-2023
         {
            meta: { title: 'Re-Hire Employee' },
            path: 'rehire-employee',
            component: ReHireEmployee
         },
         {
            meta: { title: 'Exit Interview' },
            path: 'exit-interview',
            component: ExitInterview
         },
         {
            meta: { title: 'Exit Interview Report' },
            path: 'exit-interview-report',
            component: ExitInterviewReport
         },
        /*** End of Employee Management **/

        /*** Time Keeping **/
        {
          meta: { title: 'Timekeeping Dashboard' },
          path: 'home/dashboard',
          component: TimekeepingDashboard
        },
        //by department
        {
          meta: { title: 'My Department Schedule' },
          path: 'set/schedule',
          component: SetByDepartmentSchedule
        },
        {
         meta: { title: 'Set Schedule' },
         path: 'monthly/schedule/upload',
         component: MonthlyUploadschedule
        },
        // {
        //   meta: { title: 'Upload Schedule' },
        //   path: 'hourly/schedule/upload',
        //   component: HourlyUploadschedule
        // },
        {
          meta: { title: 'Upload TimeLogs' },
          path: 'timelogs/upload',
          component: UploadTimelogs
        },
        {
          meta: { title: 'Detailed TK' },
          path: 'detailed/tk',
          component: DetailedTK
        },
        {
          meta: { title: 'Detailed Raw TK' },
          path: 'monthly/raw/tk',
          component: DetailedRawTK
        },
        {
          meta: { title: 'Hourly Detailed TK' },
          path: 'hourly/detailed/tk',
          component: HourlyDetailedTK
        },
        {
          meta: { title: 'TK Summary' },
          path: 'tk/summary',
          component: TKSummary
        },
        {
          meta: { title: 'Pending Request' },
          path: 'tk/pending/request',
          component: TKPendingRequest
        },
        {
          meta: { title: 'TK Request History' },
          path: 'tk/request/history',
          component: TKPendingRequestHistory
        },
        {
          meta: { title: 'Binary Schedule' },
          path: 'binary/upload',
          component: BinarySchedule
        },
        {
          meta: { title: 'Binary Detailed TK' },
          path: 'binary/detailed/tk',
          component: BinaryDetailedTK
        },
        {
          meta: { title: 'Binary TK Summary' },
          path: 'binary/tk/summary',
          component: BinarySummaryTK
        },
        {
          meta: { title: 'Generate DTR' },
          path: 'generate/dtr',
          component: GenerateDTR
        },
        {
          meta: { title: 'MY Calendar' },
          path: 'my/calendar',
          name: 'MyCalendar',
          component: MyCalendar
        },
        {
          meta: { title: 'Holidays' },
          path: 'holidays',
          name: 'Holidays',
          component: Holidays
        },
        {
          meta: { title: 'Adjustments' },
          path: 'adjusments',
          name: 'adjusments',
          component: Adjustments
        },
        {
          meta: { title: 'Loan Deductions' },
          path: 'deductions',
          name: 'deductions',
          component: LoanDeductions
        },
        {
          meta: { title: 'Lock Settings' },
          path: 'lock/settings',
          component: LockSettings
        },
        {
          meta: { title: 'Periodic Table' },
          path: 'periodic/table',
          component: PeriodicTable
        },
        /*** End of Time Keeping **/

        /*** Payroll **/
        {
          meta: { title: 'View Employees' },
          path: 'employee/view',
          component: ViewEmployees
        },
        {
          meta: { title: 'Process Payroll' },
          path: 'process',
          component: ProcessPayroll
        },
        {
          meta: {title: 'Payroll History'},
          path: 'payroll-history',
          component: PayrollHistory
        },
        /*** End of Payroll **/

        /*** Admin Settings **/
        {
          meta: { title: 'User List' },
          path: 'user/list',
          component: UserList
        },
        {
          meta: { title: 'Region List' },
          path: 'region/list',
          component: RegionList
        },
        {
          meta: { title: 'Province List' },
          path: 'province/list',
          component: ProvinceList
        },
        {
          meta: { title: 'Coverage List' },
          path: 'coverage/list',
          component: CoverageList
        },
        {
          meta: { title: 'Sector List' },
          path: 'sector/list',
          component: SectorList
        },
        {
          meta: { title: 'GCA List' },
          path: 'gca/list',
          component: GCAList
        },
        {
          meta: { title: 'MWEMonthly List' },
          path: 'mwe-monthly/list',
          component: MWEMonthlyList
        },
        {
          meta: { title: 'MWEDaily List' },
          path: 'mwe-daily/list',
          component: MWEDailyList
        },
        {
          meta: { title: 'Company List' },
          path: 'company/list',
          component: CompanyList
        },
        {
          meta: { title: 'Employee Status List' },
          path: 'status/list',
          component: StatusList
        },
        {
          meta: { title: 'Bank List' },
          path: 'bank/list',
          component: BankList
        },
        {
          meta: { title: 'Rank List' },
          path: 'rank/list',
          component: RankList
        },
        {
          meta: { title: 'Course List' },
          path: 'course/list',
          component: CourseList
        },
        {
          meta: { title: 'Account List' },
          path: 'account/list',
          component: AccountList
        },
        {
          meta: { title: 'Payclass List' },
          path: 'payclass/list',
          component: PayclassList
        },
        {
          meta: { title: 'Location List' },
          path: 'location/list',
          component: LocationList
        },
        {
          meta: { title: 'Position List' },
          path: 'position/list',
          component: PositionList
        },
        {
          meta: { title: 'Civil-Status List' },
          path: 'civil-status/list',
          component: CivilStatusList
        },
        {
          meta: { title: 'Cost-Center List' },
          path: 'cost-center/list',
          component: CostCenterList
        },
        {
          meta: { title: 'Tax-Status List' },
          path: 'tax-status/list',
          component: TaxStatusList
        },
        {
          meta: { title: 'Department List' },
          path: 'department/list',
          component: DepartmentList
        },
        {
          meta: { title: 'Accountability List' },
          path: 'accountability/list',
          component: AccountabilityList
        },
        {
          meta: { title: 'Payment-Mode List' },
          path: 'payment-mode/list',
          component: PaymentModeList
        },
        {
          meta: { title: 'Immediate Head List' },
          path: 'immediate-head/list',
          component: ImmediateHeadList
        },
        {
          meta: { title: 'Division Head List' },
          path: 'division-head/list',
          component: DivisionHeadList
        },
        {
          meta: { title: 'Employment Status List' },
          path: 'employment-status/list',
          component: EmploymentStatusList
        },
        {
          meta: { title: 'Deactivation Reason List' },
          path: 'deactivation-reasons/list',
          component: DeactivationReasonList
        },
        {
          meta: { title: 'Relationship List' },
          path: 'relationship/list',
          component: RelationshipList
        },
        {
          meta: { title: 'Deduction List' },
          path: 'deduction/list',
          component: Deduction
        },
        {
          meta: { title: 'Adjustment List' },
          path: 'adjustment/list',
          component: Adjustment
        },
        {
          meta: { title: 'Module Permissions Access' },
          path: 'module-permissions-access',
          component: ModulePermissionsAccess
        },
        {
            meta: { title: 'Employee Logs' },
            path: 'employee-logs',
            component: EmployeeLogs
         },
         // 06072022
        {
          meta: { title: 'For Review' },
          path: 'for-review',
          component: ForReviewEmployees
        },
        {
          meta: { title: 'Set As Approver' },
          path: 'set-as-approver',
          component: SetAsApprover
        },
        /*** End of Admin Settings **/

        /*** Online Forms **/
        {
          meta: { title: 'My Form Applications' },
          path: 'my-form-applications',
          name: 'MyApplications_Form',
          component: MyApplications_Form
        },
        {
          meta: { title: 'Change Schedule' },
          path: 'change-schedule',
          name: 'ChangeSchedule',
          component: ChangeSchedule
        },
        {
          meta: { title: 'Halfday' },
          path: 'halfday',
          name: 'Halfday',
          component: Halfday
        },
        {
          meta: { title: 'Official Business Trip' },
          path: 'business-trip',
          name: 'OfficialBusinessTrip',
          component: OfficialBusinessTrip
        },
        {
          meta: { title: 'Overtime' },
          path: 'overtime',
          name: 'Overtime',
          component: Overtime
        },
        {
          meta: { title: 'Undertime' },
          path: 'undertime',
          name: 'Undertime',
          component: Undertime
        },
        {
          meta: { title: 'Work From Home' },
          path: 'wfh',
          name: 'wfh',
          component: WorkFromHome
        },
        {
          meta: { title: 'COE Request Form' },
          path: 'request-coe',
          name: 'COE Request',
          component: COERequest
        },
        {
          meta: { title: 'My Form Approvals' },
          path: 'my-form-approvals',
          name: 'MyApprovals_Form',
          component: MyApprovals_Form
        },
        {
          meta: { title: 'COE Request List' },
          path: 'coe-request-list',
          name: 'COE Request List',
          component: COERequestList
        },
        {
          meta: { title: 'Set Form Approvers' },
          path: 'set-form-approvers',
          name: 'SetApprovers_Form',
          component: SetApprovers_Form
        },
        {
          meta: { title: 'Employee Form Applications' },
          path: 'employee-form-applications',
          name: 'EmployeeApplications_Form',
          component: EmployeeApplications_Form
        },
        {
          meta: { title: 'Forms Reports' },
          path: 'forms-reports',
          name: 'Forms_Reports',
          component: FormsApplicationReports
        },
        {
          meta: { title: 'Forms Type' },
          path: 'forms-types',
          name: 'Forms_Types',
          component: FormsTypes
        },
        /*** End of Online Forms **/

        /** Leave **/
        {
          meta: { title: 'My Leave Applications' },
          path: 'my-leave-applications',
          name: 'MyApplications_Leave',
          component: MyApplications_Leave
        },
        {
          meta: { title: 'Leave Application' },
          path: 'application-form',
          name: 'LeaveApplication',
          component: LeaveApplication
        },
        {
          meta: { title: 'My Leave Credits' },
          path: 'my-leave-credits',
          name: 'MyLeaveCredits',
          component: MyLeaveCredits
        },
        {
          meta: { title: 'My Leave Approvals' },
          path: 'my-leave-approvals',
          name: 'MyApprovals_Leave',
          component: MyApprovals_Leave
        },
        {
          meta: { title: 'Set Leave Approvers' },
          path: 'set-leave-approvers',
          name: 'SetApprovers_Leave',
          component: SetApprovers_Leave
        },
        {
          meta: { title: 'Employee Leave Applications' },
          path: 'employee-leave-applications',
          name: 'EmployeeApplications_Leave',
          component: EmployeeApplications_Leave
        },
        {
          meta: { title: 'Employee Leave Credits' },
          path: 'employee-leave-credits',
          name: 'EmployeeLeaveCredits',
          component: EmployeeLeaveCredits
        },
        {
          meta: { title: 'Special Leave Applications' },
          path: 'special-leave-applications',
          name: 'SpecialLeaveApplications',
          component: SpecialLeaveApplications
        },
        {
          meta: { title: 'Leaves Reports' },
          path: 'leave-reports',
          name: 'LeaveReports',
          component: LeaveApplicationReports
        },
        {
          meta: { title: 'Remaining Leaves Reports' },
          path: 'remaining-leaves',
          name: 'RemainingLeaves',
          component: RemainingLeaveApplicationReports
        },
        {
          meta: { title: 'Leaves History' },
          path: 'employee-leave-history',
          name: 'LeaveHistory',
          component: EmployeeLeavesHistory
        },
        /** End of Leave **/

        /** Recruitment **/
        {
         meta: { title: 'For Pool List' },
         path: 'pool-list',
         name: 'ForPoolList',
         component: ForPoolList
       },
       {
          meta: { title: 'Recruitment Dashboard' },
          path: 'dashboard-list',
          name: 'ForDashboard',
          component: ForDashboard
        },
       //05-27-2022
        {
          meta: { title: 'Pool List' },
          path: 'pool-list2',
          name: 'ForPoolList2',
          component: ForPoolList2
        },
       {
         meta: { title: 'Exam List' },
         path: 'exam-list',
         name: 'ForExamList',
         component: ForExamList
       },
       {
         meta: { title: 'Exam Result List' },
         path: 'exam-result-list',
         name: 'ForExamResultList',
         component: ForExamResultList
       },
       {
         meta: { title: 'Initial Interview' },
         path: 'initial-interview-list',
         name: 'ForInitialInterview',
         component: ForInitialInterview
       },
       {
         meta: { title: 'Final Exam' },
         path: 'final-exam-list',
         name: 'ForFinalExam',
         component: ForFinalExam
       },
       {
         meta: { title: 'Final Interview' },
         path: 'final-interview-list',
         name: 'ForFinalInterview',
         component: ForFinalInterview
       },
       {
         meta: { title: 'Training List' },
         path: 'training-list',
         name: 'ForTrainingList',
         component: ForTrainingList
       },
       {
         meta: { title: 'Hired List' },
         path: 'hired-list',
         name: 'ForHiredList',
         component: ForHiredList
       },
       {
          meta: { title: 'Dump List' },
          path: 'dump-list',
          name: 'ForDumpList',
          component: ForDumpList
        },
        {
          meta: { title: 'Re-Hire' },
          path: 're-hire',
          name: 'ReHireList',
          component: ReHireList
        },

        //ruben :sms-blast 22-06-2023
      {
         meta: { title: 'SMS-Blast' },
         path: 'sms-blast',
         name: 'SmsBlast',
         component: SmsBlast
      },
      {
         meta: { title: 'SMS Template' },
         path: 'sms-template',
         name: 'SmsTemplate',
         component: SmsTemplate
      },

        // {
        //   meta: { title: 'Employment Application' },
        //   path: 'employment-application',
        //   name: 'EmploymentApplication',
        //   component: EmploymentApplication
        // },
        /** End of Recruitment **/


        /** Announcement **/
        {
          meta: { title: 'Announcement Dashboard' },
          path: 'announcement-list',
          name: 'Announcement',
          component: AnnouncementDashboard
        },
        {
          meta: { title: 'Admin Dashboard' },
          path: 'admin-dashboard',
          name: 'AdminDashboard',
          component: AdminDashboard
        },
        /** End of Announcement **/

        /** Mange Database **/
        {
          meta: { title: 'Knowledge Database' },
          path: 'database-dashboard',
          name: 'Database',
          component: DatabaseDashboard
        },
        {
          meta: { title: 'Masterlist of Internal Records' },
          path: 'database-masterlist',
          name: 'Masterlist',
          component: DatabaseMasterlist
        },
        {
          meta: { title: 'HR Files' },
          path: 'hr-files',
          name: 'HR',
          component: HRFiles
        },
        {
          meta: { title: 'IT Files' },
          path: 'it-files',
          name: 'IT',
          component: ITFiles
        },
        {
          meta: { title: 'QA Files' },
          path: 'qa-files',
          name: 'QA',
          component: QAFiles
        },
        {
          meta: { title: 'ADMIN Files' },
          path: 'admin-files',
          name: 'ADMIN',
          component: ADMINFiles
        },
        {
          meta: { title: 'JOLLIBEE Files' },
          path: 'jollibee-files',
          name: 'JOLLIBEE',
          component: JOLLIBEEFiles
        },
        {
          meta: { title: 'GREENWICH Files' },
          path: 'greenwich-files',
          name: 'GREENWICH',
          component: GREENWICHFiles
        },
        {
          meta: { title: 'AMBER Files' },
          path: 'amber-files',
          name: 'AMBER',
          component: AMBERFiles
        },
        {
          meta: { title: 'PLDT Files' },
          path: 'pldt-files',
          name: 'PLDT',
          component: PLDTFiles
        },
        {
          meta: { title: 'SMART Files' },
          path: 'smart-files',
          name: 'SMART',
          component: SMARTFiles
        },
        {
          meta: { title: 'DITO Files' },
          path: 'dito-files',
          name: 'DITO',
          component: DITOFiles
        },
        {
          meta: { title: 'ACFE Files' },
          path: 'acfe-files',
          name: 'ACFE',
          component: ACFEFiles
        },
        {
          meta: { title: 'HI-PRECISION Files' },
          path: 'hi-precision-files',
          name: 'HI-PRECISION',
          component: HIPRECISIONFiles
        },
        {
          meta: { title: 'GALLEON Files' },
          path: 'galleon-files',
          name: 'GALLEON',
          component: GALLEONFiles
        },
        {
          meta: { title: 'TOYOTA Files' },
          path: 'toyota-files',
          name: 'TOYOTA',
          component: TOYOTAFiles
        },
        {
          meta: { title: 'DLG Files' },
          path: 'dlg-files',
          name: 'DLG',
          component: DLGFiles
        },
        /** End of Mange Database **/

        /** Payslip **/
         {
            meta: { title: 'Payslip' },
            path: 'payslip-dashboard',
            name: 'Payslip',
            component: PayslipDashboard
         },
         /** End of Payslip **/


        /** Backend **/
        {
          meta: { title: 'Backend Dashboard' },
          path: 'backend-list',
          name: 'Backend',
          component: BackendDashboard
        },
        {
          meta: { title: 'Vertical Summary' },
          path: 'vertical-summary',
          name: 'VerticalDetailed',
          component: VerticalDetailed
        },
        {
          meta: { title: 'Detailed Payslip' },
          path: 'detailed-payslip',
          name: 'DetailedPayslip',
          component: DetailedPayslip
        },
        {
          meta: { title: 'SSS Employee Contribution' },
          path: 'sss-employee-contribution',
          name: 'SSS Employee Contribution',
          component: SSSEmployeeContribution
        },
        {
          meta: { title: 'Philhealth Employee Contribution' },
          path: 'philhealth-employee-contribution',
          name: 'Philhealth Employee Contribution',
          component: PhilHealthEmployeeContribution
        },
        {
          meta: { title: 'Pagibig Employee Contribution' },
          path: 'pagibig-employee-contribution',
          name: 'Pagibig Employee Contribution',
          component: PagibigEmployeeContribution
        },
        {
          meta: { title: 'Alphalist' },
          path: 'alphalist',
          name: 'alphalist',
          component: Alphalist
        },
        {
          meta: { title: 'Total Gov Contribution' },
          path: 'total-gov-contribution',
          name: 'Total Gov Contribution',
          component: TotalGovContribution
        },
        {
          meta: { title: 'SSS Table' },
          path: 'sss-table',
          name: 'SSSTable',
          component: SSSTable
        },
        {
          meta: { title: 'PhilHealth Table' },
          path: 'philHealth-table',
          name: 'PhilHealthTable',
          component: PhilHealth
        },
        {
          meta: { title: 'Pag-ibig Table' },
          path: 'pagibig-table',
          name: 'PagibigTable',
          component: Pagibig
        },
        {
          meta: { title: 'Tax Table' },
          path: 'tax-table',
          name: 'TaxTable',
          component: TaxTable
        },
        {
          meta: { title: 'Tax Status Table' },
          path: 'tax-status-table',
          name: 'TaxStatusTable',
          component: TaxStatus
        },
        {
          meta: { title: 'Holiday & OT Rates Table' },
          path: 'holiday-otrates-table',
          name: 'HolidayOTRates',
          component: HolidayOTRates
        },
        /** End of Backend **/

        // ruben:08/08/2023
        //Pantry
        {
          meta: { title: 'Search or Sync Employees' },
          path: 'sync-employees',
          name: 'EmployeeList',
          component: EmployeeList
        },
        {
          meta: { title: 'Regular Employee Report' },
          path: 'regular-employee-report',
          name: 'RegularEmployeeReport',
          component: RegularEmployeeReport
        },
        {
          meta: { title: 'Trainee Employees' },
          path: 'trainee-employees',
          name: 'EmployeeList',
          component: TraineeEmployeeList
        },
        {
          meta: { title: 'Trainee Employee Report' },
          path: 'trainee-employee-report',
          name: 'TraineeEmployeeReport',
          component: TraineeEmployeeReport
        },
        // add by mark 11/25/2024
        {
          meta: { title: 'Account Receivable' },
          path: 'account-receivable',
          name: 'AccountReceivable',
          component: AccountReceivable
        },
        {
          meta: { title: 'Account Payable' },
          path: 'account-payable',
          name: 'AccountPayable',
          component: AccountPayable
        },
        {
          meta: { title: 'Account Receivable Report' },
          path: 'ar-report',
          name: 'ARReport',
          component: AccountReceivableReport
        },
        {
          meta: { title: 'Account Payable Report' },
          path: 'ap-report',
          name: 'APReport',
          component: AccountPayableReport
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  let cookie = VueCookies.get(mixins.data().cookieKey);

  if (cookie == null && to.name != 'Login') {
    router.push('/login');
  } else if (cookie != null && to.name == 'Login') {
    if (cookie.user.username == 'NewApplicant') {
      router.push('/employment-application');
    } else {
      router.push('/home');
    }
  } else {
    document.title = to.meta.title
    next();
  }
});

export default router;
