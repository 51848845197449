<template>
   <q-dialog v-model="dialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">COE Request Details</div>
        </q-card-section>

        <q-separator />

        <q-card-section style="max-height: 50vh" class="scroll">
           <q-list bordered separator>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>{{ obj.id }}</q-item-label>
                <q-item-label caption>Request No.</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>{{ obj.name }}</q-item-label>
                <q-item-label caption>Name</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple v-show="obj.position != ''">
              <q-item-section>
                <q-item-label>{{ obj.position }}</q-item-label>
                <q-item-label caption>Position</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>{{ obj.request_date }}</q-item-label>
                <q-item-label caption>Request Date</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>{{ obj.reason }}</q-item-label>
                <q-item-label caption>Reason</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple v-show="obj.release_date != ''">
              <q-item-section>
                <q-item-label>{{ obj.release_date }}</q-item-label>
                <q-item-label caption>Release Date</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple>
              <q-item-section>
                <q-item-label>{{ obj.status }}</q-item-label>
                <q-item-label caption>Status</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon :name="obj.status == 'PENDING' ?  'fa fa-clock' : obj.status == 'PROCESSING' ? 'fa fa-thumbs-up' : 'fa fa-check-circle'"
                :color="obj.status == 'PENDING' ? 'orange' : obj.status == 'PROCESSING' ? 'teal' : 'green'" />
              </q-item-section>
            </q-item>

          </q-list>
        </q-card-section>

        <q-separator />

        <q-card-actions align="right">
          <q-btn flat label="Close" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>
<script>
import axios from 'axios';
import mixins from '../../mixins';

export default {

  data() {
    return {
      dialog:false,
      obj:{}
    }
  },
  created(){
    this.eventHub.$on('COEDetails', this.COEDetails);
  },

  methods: {

    COEDetails:function(payload){
      this.dialog = payload.stat
      this.obj = payload.obj
      console.log(payload.obj)
    },


   }
}
</script>
<style lang="css" scoped>
</style>
