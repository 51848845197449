<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
        <div class="row q-mb-md full-width">
          <div class="full-width" style="height: 40px;">
            <h4 class="q-ma-none row inline">COE Request</h4>
          </div>
        </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.coeReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" class="caps" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="lg" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="lg" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
let dateToday = date.formatDate(today, 'YYYY/MM/DD');
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'OfficialBusinessTrip',
  props: ['value'],
  data() {
    return {
      loading: false,
      form: {
        coeReason: ''
      }
    }
  },
  methods: {

    submitApplication: function() {
      if (this.form.coeReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please write your reason.'
        });
      } else {
        this.loading = true;

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));
        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/coe', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: 'COE Request Submitted!'
            });
            this.resetForm();
          }, 1000);
        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
}
</script>
<style scoped>
</style>
